import React, { useState, useEffect, useRef } from 'react';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { useCookies } from 'react-cookie';
import AdminServiciosAPI from '../servicios/AdminServicios';
import { BlockUI } from 'primereact/blockui';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import { InputTextarea } from 'primereact/inputtextarea';
import EditarEnlaces from '../comun/EditarEnlaces';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import Recursos from './Recursos';
import { Constantes } from '../comun';

const Materia = () => { 
    const [cookies, , removeCookie] = useCookies(['user']);
    const [nombre, setNombre] = useState('');
    const [dominio, setDominio] = useState('');
    const [fecFin, setFecFin] = useState('');
    const [sitio, setSitio] = useState('');
    const [estilo, setEstilo] = useState('');
    const [cargando, setCargando] = useState(false);
    const [enlaces, setEnlaces] = useState([]);
    const [textoContacto, setTextoContacto] = useState('');
    const toast = useRef(null);
    const fileRecursos = useRef(null);
    const filePaginaInicio = useRef(null);
    const filePaginaRegistro = useRef(null);
    const enlacesRef = useRef(null);
    const [creditos, setCreditos] = useState(0);
    const [duracion, setDuracion] = useState(0);
    const [fechaInicio, setFechaInicio] = useState('');
    const [fechaFin, setFechaFin] = useState('');
    const [resumen, setResumen] = useState('');
    const [clave, setClave] = useState('');
    const [codigoBody, setCodigoBody] = useState('');
    const [textoBasicoInicio, setTextoBasicoInicio] = useState('')
    const [textoBasicoRegistro, setTextoBasicoRegistro] = useState('')
    const [apiKey, setApiKey] = useState('')

    useEffect( () => {
        cargarCurso();
    }, []);

    async function cargarCurso(){
        setCargando(true);
        const response = await AdminServiciosAPI.getMateria(cookies.CRCOOKIEUSUARIO,cookies.CRCOOKIEMATERIA.id);
		if(response.ok){
            let curso = await response.json();
            if(curso.length > 0){
                curso = curso[0];
                setNombre(curso.nombre);
                setDominio(curso.dominio);
                setFecFin(curso.fec_finalizacion);
                setSitio(curso.sitio_nombre);
                setApiKey(curso.apikey)
                if(curso.datos){
                    const datoscurso = JSON.parse(curso.datos);
                    //console.log('enlaces',datoscurso.enlaces);
                    setEnlaces(datoscurso.enlaces);
                    setEstilo(datoscurso.estilo);
                    setTextoContacto(datoscurso.textocontacto);
                    setCreditos(datoscurso.creditos);
                    setDuracion(datoscurso.duracion);
                    setFechaInicio(new Date(datoscurso.fechaInicio));
                    setFechaFin(new Date(datoscurso.fechaFin));
                    setResumen(datoscurso.resumen);
                    setClave(datoscurso.clave);
                    datoscurso.textoBasicoInicio && setTextoBasicoInicio(datoscurso.textoBasicoInicio)
                    datoscurso.textoBasicoRegistro && setTextoBasicoRegistro(datoscurso.textoBasicoRegistro)
                    datoscurso.codigoBody && setCodigoBody(Buffer.from(datoscurso.codigoBody).toString('utf8'))
                    cookies.CRCOOKIEMATERIA.plan===Constantes.planProfesional && enlacesRef.current.actualizarEnlaces(datoscurso.enlaces)
                }
            }else{
                removeCookie('CRUSUARIO');
                removeCookie('CRCOOKIEUSUARIO');
                removeCookie('CRCOOKIEMATERIA');
                window.location.reload(false);
            }
		}
        setCargando(false);
    }

    const onGuardar = async () => {
        setCargando(true);
        const datos = {
            nombre: nombre,
            dominio: dominio,
            datos: JSON.stringify({estilo:estilo, enlaces: enlaces, textocontacto: textoContacto, creditos: creditos, duracion: duracion, fechaInicio: fechaInicio, fechaFin: fechaFin, resumen: resumen, clave:clave, codigoBody: codigoBody, textoBasicoInicio: textoBasicoInicio, textoBasicoRegistro: textoBasicoRegistro})
        }
        const where = {
            id: cookies.CRCOOKIEMATERIA.id
        }
        const response = await AdminServiciosAPI.editarRegistro(cookies.CRCOOKIEUSUARIO,'materias',{datos:datos,where:where});
		const guardado = await response.json();
        toast.current.show({severity:response.ok?'success':'error', detail:guardado, life: 3000});
        setCargando(false);
    }

    const onUploadPaginaInicio = (event,pagina) => {
        setCargando(true);
        const [file] = event.files;
        const fileReader = new FileReader();
        fileReader.onloadend = (e) => {
            enviarArchivo(e.target.result.split(",")[1],file.type,pagina);
        };
        fileReader.readAsDataURL(file);
    }

    const enviarArchivo = async (archivo,type,pagina) => {
        //console.log('type',type)
        const responseurl = await AdminServiciosAPI.getUrlSubirArchivo(cookies.CRCOOKIEUSUARIO,`getuploadurl/${pagina}/${cookies.CRCOOKIEMATERIA.id}`);
        const urlsigned = await responseurl.json();
        //console.log('url',urlsigned);
        const buf = Buffer.from(archivo,'base64');
        const result = await fetch(urlsigned.uploadURL, {
            method: 'PUT',
            body: buf
        });
        //console.log('grabado',result);
        if(result.ok){
            const response = await AdminServiciosAPI.enviarArchivo(cookies.CRCOOKIEUSUARIO,`${pagina}/${cookies.CRCOOKIEMATERIA.id}`,{archivo: 'zip.zip'});
            const guardado = await response.json();
            //console.log('resultado',guardado)
            toast.current.show({severity:response.ok?'success':'error', detail:guardado, life: 3000});
            if(pagina==='recursos'){
                fileRecursos.current.clear();
            }else if(pagina==='paginainicio'){
                filePaginaInicio.current.clear();
            }else{
                filePaginaRegistro.current.clear();
            }
        }else{
            toast.current.show({severity:'error', detail:'Lo sentimos no hemos podido almacenar el archivo, por favor intente en unos minutos.', life: 3000});
        }
        setCargando(false);
    }

    function modificarEnlaces(listadoNuevo){
        setEnlaces(listadoNuevo);
    }

    const leftContents = (
        <React.Fragment>
            <Button label="Guardar" icon="pi pi-save" className="p-mr-2" onClick={() => {onGuardar()}}/>
        </React.Fragment>
    );

    return (
        <BlockUI blocked={cargando}>
            <Toolbar className="mb-3 toolbaredicionmateria" left={leftContents} />
            <Toast ref={toast} />
            <Fieldset legend={nombre}>
                <div className="mb-3">
                    <label htmlFor="nombreCurso" className="form-label">Nombre del Curso</label>
                    <InputText id="nombrecurso" className="p-d-block form-control" onChange={(e) => setNombre(e.target.value)} value={nombre}/>
                </div>
                <div className="mb-3">
                    <label htmlFor="nombreCurso" className="form-label">Pertenece a</label>
                    <InputText id="nombrecurso" className="p-d-block form-control" value={sitio} disabled/>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label htmlFor="dominioCurso" className="form-label">Dominio</label>
                        <InputText id="dominioCurso" className="p-d-block form-control" onChange={(e) => setDominio(e.target.value)} value={dominio}/>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="nombreCurso" className="form-label">Plan</label>
                        <InputText id="nombrecurso" className="p-d-block form-control" value={cookies.CRCOOKIEMATERIA.plan==='U'?'Ilimitado':'Básico'} disabled/>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="nombreCurso" className="form-label">Máximo de estudiantes</label>
                        <InputText id="nombrecurso" className="p-d-block form-control" value={cookies.CRCOOKIEMATERIA.estudiantes} disabled/>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label htmlFor="fecfinalCurso" className="form-label">Fecha Finalización</label>
                        <InputText id="fecfinalCurso" className="p-d-block form-control" value={fecFin} readOnly/>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="claveCurso" className="form-label">Clave para todos los estudiantes</label>
                        <InputText id="claveCurso" className="p-d-block form-control" value={clave} onChange={(e) => setClave(e.target.value)}/>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="apikeyCurso" className="form-label">Api Key para Integraciones</label>
                        <InputText id="apikeyCurso" className="p-d-block form-control" value={apiKey} readOnly/>
                    </div>
                </div>
                
                <div className="mb-3">
                    <label htmlFor="textocontacto" className="form-label">Texto Contacto</label>
                    <InputTextarea id="textocontacto" className="p-d-block form-control" style={{height: "100px"}} value={textoContacto} onChange={(e) => setTextoContacto(e.target.value)} rows={5} cols={30} />
                </div>
                {cookies.CRCOOKIEMATERIA.plan===Constantes.planProfesional && <div className="row mb-3">
                    <div className="col-md-3">
                        <label htmlFor="creditos" className="form-label">Créditos</label>
                        <InputText id="creditos" className="p-d-block form-control" onChange={(e) => setCreditos(e.target.value)} value={creditos}/>
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="duracion" className="form-label">Duración (horas)</label>
                        <InputNumber inputId="duracion" value={duracion} onValueChange={(e) => setDuracion(e.value)} mode="decimal" showButtons min={0} max={100} />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="fechainicio" className="form-label">Fecha Inicio</label>
                        <Calendar id="fechainicio" value={fechaInicio} onChange={(e) => setFechaInicio(e.value)} showIcon />
                    </div>
                    <div className='col-md-3'>
                    <label htmlFor="fechafin" className="form-label">Fecha Fin</label>
                        <Calendar id="fechafin" value={fechaFin} onChange={(e) => setFechaFin(e.value)} showIcon />
                    </div>
                </div>}
                {cookies.CRCOOKIEMATERIA.plan===Constantes.planProfesional && <div className="mb-3">
                    <label htmlFor="filePaginaInicio" className="form-label">Página de Inicio (Subir archivo .zip Máx 30Mb)</label>
                    <FileUpload ref={filePaginaInicio} id="filePaginaInicio" name="pagina-inicio" customUpload uploadHandler={(e)=>onUploadPaginaInicio(e,'paginainicio')}  accept=".zip" maxFileSize="31457280"></FileUpload>
                </div>}
                {cookies.CRCOOKIEMATERIA.plan===Constantes.planProfesional && <div className="mb-3">
                    <label htmlFor="filePaginaRegistro" className="form-label">Página de Registro (Subir archivo .zip Máx 30Mb)</label>
                    <FileUpload ref={filePaginaRegistro} id="filePaginaRegistro" name="pagina-registro" customUpload uploadHandler={(e)=>onUploadPaginaInicio(e,'paginaregistro')}  accept=".zip" maxFileSize="31457280"></FileUpload>
                </div>}
                <div className="mb-3">
                    <label htmlFor="fileRecursos" className="form-label">Otros recursos (Subir archivo .zip Máx 30Mb)</label>
                    <p>Archivos (cada archivo debe ser menor a 10Mb): logo.png, favicon.png, publicidad-superior.png, publicidad-lateral.png, logo-pie-1.png, logo-pie-2.png, certificado.docx, etc...</p>
                    <p>No se permiten cargar archivos de video, para los videos por favor use plataformas como Youtube o Vimeo.</p>
                    <p>Para certificados por tipo de estudiante el nombre debe tener el id del tipo de estudiante por ejemplo: certificado-tipo77.docx</p>
                    <p>Para certificados por módulo el nombre debe tener el orden del modulo por ejemplo: certificado-modulo1.docx</p>
                    <p>El certificado debe ser en formato .docx y debe tener la variable &#x24;{`{nombre}`} o en el caso de certificado por módulo puede incluir &#x24;{`{fechaaprobacion}`}.  Si necesita reemplazar el certificado por favor primero eliminarlo de la lista.</p>
                    <FileUpload ref={fileRecursos} id="fileRecursos" name="otros-recursos" customUpload uploadHandler={(e)=>onUploadPaginaInicio(e,'recursos')}  accept=".zip" maxFileSize="31457280"></FileUpload>
                </div>
                <div className="mb-3">
                    <label htmlFor="listadoRecursos" className="form-label">Listado de Recursos</label>
                    <Recursos cookies={cookies} cargando={cargando}/>
                </div>
                {cookies.CRCOOKIEMATERIA.plan===Constantes.planProfesional && <div className="mb-3">
                    <label htmlFor="enlaces" className="form-label">Enlaces de Publicidades</label>
                    <p>Esta sección es para asignar una url a la imagen de publicidad cargada desde recursos, el nombre debe ser igual al nombre del archivo ejemplo publicidad-superior.png</p>
                    <EditarEnlaces ref={enlacesRef} id="enlaces" listaEnlaces={enlaces} modificarEnlaces={modificarEnlaces} />
                </div>}
                {cookies.CRCOOKIEMATERIA.plan===Constantes.planProfesional && <div className="mb-3">
                    <label htmlFor="resumen" className="form-label">Resumen del contenido del curso</label>
                    <InputTextarea id="resumen" className="p-d-block form-control" style={{height: "200px"}} value={resumen} onChange={(e) => setResumen(e.target.value)} rows={10} cols={30} />
                </div>}
                {cookies.CRCOOKIEMATERIA.plan===Constantes.planProfesional && <div className="mb-3">
                    <label htmlFor="estilos" className="form-label">Estilos del panel para el usuario</label>
                    <InputTextarea id="estilos" className="p-d-block form-control" style={{height: "200px"}} value={estilo} onChange={(e) => setEstilo(e.target.value)} rows={10} cols={30} />
                </div>}
                {cookies.CRCOOKIEMATERIA.plan===Constantes.planProfesional && <div className="mb-3">
                    <label htmlFor="codigobody" className="form-label">Código Body personalizado, puede ser código javascript o html para incluir en el sistema dentro de la etiqueta body</label>
                    <InputTextarea id="codigobody" className="p-d-block form-control" style={{height: "200px"}} value={codigoBody} onChange={(e) => setCodigoBody(e.target.value)} rows={10} cols={30} />
                </div>}
                {cookies.CRCOOKIEMATERIA.plan===Constantes.planBasico && <div className="mb-3">
                    <label htmlFor="basinicio" className="form-label">Texto página de Inicio, se ubicará en la parte de abajo del título</label>
                    <InputTextarea id="basinicio" className="p-d-block form-control" style={{height: "200px"}} value={textoBasicoInicio} onChange={(e) => setTextoBasicoInicio(e.target.value)} rows={10} cols={30} />
                </div>}
                {cookies.CRCOOKIEMATERIA.plan===Constantes.planBasico && <div className="mb-3">
                    <label htmlFor="basregistro" className="form-label">Texto para formulario de registro, se ubicará en la parte de abajo del formulario de registro</label>
                    <InputTextarea id="basregistro" className="p-d-block form-control" style={{height: "200px"}} value={textoBasicoRegistro} onChange={(e) => setTextoBasicoRegistro(e.target.value)} rows={10} cols={30} />
                </div>}
            </Fieldset>
        </BlockUI>
    );
}

export default Materia