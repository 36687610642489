import React, { useState, useRef } from 'react';
import { useCookies } from 'react-cookie';
import Tabla from '../core/Tabla';
import AdminServiciosAPI from '../servicios/AdminServicios';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import EditarEstudiante from '../estudiantes/EditarEstudiante';
import DatosPerfil from '../estudiantes/DatosPerfil';
import { Toast } from 'primereact/toast';
import TipoEstudiante from '../estudiantes/TipoEstudiante';
import { Constantes } from '../comun';

const Estudiante = () => { 
    const [cookies] = useCookies(['user']);
    const [idEstudiante, setIdEstudiante] = useState(null);
    const [editarEstudiante, setEditarEstudiante] = useState(false);
    const [editarDatosPerfil, setEditarDatosPerfil] = useState(false);
    const [editarTipoEstudiante, setEditarTipoEstudiante] = useState(false);
    const [abrirImportar, setAbrirImportar] = useState(false);
    const refTabla = useRef(null);
    const mensaje = useRef(null);

    const columnas = [
        {field: 'nombres', header: 'Nombres', sortable:true, filter:true},
        {field: 'apellidos', header: 'Apellidos', sortable:true, filter:true},
        {field: 'usuario', header: 'Usuario', sortable:true, filter:true},
        {field: 'estado_nombre', header: 'Estado', sortable:true, filter:true},
        {field: 'inscripcion_fecha', header: 'Fecha de Registro', sortable:true, filter:true},
    ];
    //console.log('mat',cookies.CRCOOKIEMATERIA)
    const where = {where: {id_materia: cookies.CRCOOKIEMATERIA.id}};

    function onEditar(idestudiante){
        setIdEstudiante(idestudiante);
        setEditarEstudiante(true);
    }

    async function onDelete(seleccionados){
        let response = await AdminServiciosAPI.eliminarRegistro(cookies.CRCOOKIEUSUARIO,'estudiantes',{seleccionados: seleccionados});
        if(response.error){
            mensaje.current.show({
                severity: 'warn',
                sticky: true,
                detail: `Lo sentimos tenemos problemas con red, intente en unos minutos.`
            });
        }else{
            const errormsj = await response.json();
            mensaje.current.show({
                severity: response.ok?'info':'warn',
                sticky: true,
                detail: errormsj
            });
            response.ok && refTabla.current.loadLazyData();
        }
    }

    function onDatosPerfil(){
        setEditarDatosPerfil(true);
    }

    function onTiposEstudiante(){
        setEditarTipoEstudiante(true);
    }

    function onImportar(){
        setAbrirImportar(true);
    }

    const onCerrar = () => {
        setIdEstudiante(null);
        setEditarEstudiante(false);
    }

    const onCerrarDatosPerfil = () => {
        setEditarDatosPerfil(false);
    }

    const onCerrarTiposEstudiante = () => {
        setEditarTipoEstudiante(false);
    }

    const onCerrarImportar = () => {
        setAbrirImportar(false);
    }

    const headerTabla = (
        <div className="table-header">
            <h5 className="p-m-0">Administración de Estudiantes</h5>
        </div>
    );

    const opcionesTabla = (
            <React.Fragment>
                {cookies.CRCOOKIEMATERIA.plan==='o' && <Button label="Importar" icon="pi pi-file-excel" className="p-button-secondary me-2" onClick={() => {onImportar()}} />}
                {cookies.CRCOOKIEMATERIA.plan===Constantes.planProfesional && <Button label="Datos Perfil" icon="pi pi-id-card" className="p-button-info me-2" onClick={() => {onDatosPerfil()}} />}
                {cookies.CRCOOKIEMATERIA.plan===Constantes.planProfesional && <Button label="Tipos de Estudiante" icon="pi pi-tag" className="p-button-warning me-2" onClick={() => {onTiposEstudiante()}} />}
            </React.Fragment>
    );

    return (
        <div>
            <Toast ref={mensaje} />
            <Tabla ref={refTabla} header={headerTabla} cookies={cookies} nombreTabla='estudiantes' 
                columnas={columnas} where={where} onEditar={onEditar} onDelete={onDelete} rightToolbarTemplate={opcionesTabla}/>
            <Sidebar position="right" className="ui-sidebar-lg" visible={editarEstudiante} onHide={() => onCerrar()} icons={() => (
                <React.Fragment>
                    <h5 className="text-center">Editar Estudiante</h5>
                </React.Fragment>
            )}>
                <EditarEstudiante estudianteSeleccionado={idEstudiante} refTabla={refTabla} cookies={cookies}/>
            </Sidebar>
            <Sidebar position="right" className="ui-sidebar-lg" visible={editarDatosPerfil} onHide={() => onCerrarDatosPerfil()} icons={() => (
                <React.Fragment>
                    <h5 className="text-center">Datos de Perfiles</h5>
                </React.Fragment>
            )}>
                <DatosPerfil cookies={cookies}/>
            </Sidebar>
            <Sidebar position="right" className="ui-sidebar-lg" visible={editarTipoEstudiante} onHide={() => onCerrarTiposEstudiante()} icons={() => (
                <React.Fragment>
                    <h5 className="text-center">Tipos de Estudiante</h5>
                </React.Fragment>
            )}>
                <TipoEstudiante cookies={cookies}/>
            </Sidebar>
            <Sidebar position="right" className="ui-sidebar-lg" visible={abrirImportar} onHide={() => onCerrarImportar()} icons={() => (
                <React.Fragment>
                    <h5 className="text-center">Importar estudiantes</h5>
                </React.Fragment>
            )}>
                <div>Importando</div>
            </Sidebar>
        </div>
    );
}

export default Estudiante