import React, { useState, useRef } from 'react';
import { useCookies } from 'react-cookie';
import AdminServiciosAPI from '../servicios/AdminServicios';
import Tabla from '../core/Tabla';
import { Sidebar } from 'primereact/sidebar';
import EditarNotificacion from '../materia/EditarNotificacion';
import { Toast } from 'primereact/toast';

const Notificaciones = () => { 
    const [cookies] = useCookies(['user']);
    const [idNotificacion, setIdNotificacion] = useState(null);
    const [editarNotificacion, setEditarNotificacion] = useState(false);
    const refTabla = useRef(null);
    const mensaje = useRef(null);

    const columnas = [
        {field: 'resumen', header: 'Texto', sortable:true, filter:true},
        {field: 'fecha_inicio', header: 'Fecha Inicio', sortable:true, filter:true},
        {field: 'fecha_fin', header: 'Fecha Fin', sortable:true, filter:true},
    ];

    const where = {where: {id_materia: cookies.CRCOOKIEMATERIA.id}};

    function onEditar(idnotificacion){
        setIdNotificacion(idnotificacion);
        setEditarNotificacion(true);
    }

    async function onDelete(seleccionados){
        let response = await AdminServiciosAPI.eliminarRegistro(cookies.CRCOOKIEUSUARIO,'notificaciones',{seleccionados: seleccionados});
        if(response.error){
            mensaje.current.show({
                severity: 'warn',
                sticky: true,
                detail: `Lo sentimos tenemos problemas con red, intente en unos minutos.`
            });
        }else{
            const errormsj = await response.json();
            mensaje.current.show({
                severity: response.ok?'info':'warn',
                sticky: true,
                detail: errormsj
            });
            response.ok && refTabla.current.loadLazyData();
        }
    }

    const onCerrar = () => {
        setIdNotificacion(null);
        setEditarNotificacion(false);
    }

    const headerTabla = (
        <div className="table-header">
            <h5 className="p-m-0">Administración de Notificaciones</h5>
        </div>
    );

    return (
        <div>
            <Toast ref={mensaje} />
            <Tabla ref={refTabla} header={headerTabla} cookies={cookies} nombreTabla='notificaciones' columnas={columnas} where={where} onEditar={onEditar} onDelete={onDelete} />
            <Sidebar visible={editarNotificacion} fullScreen onHide={() => onCerrar()} icons={() => (
                <React.Fragment>
                    <h5 className="text-center">Editar Notificacion</h5>
                </React.Fragment>
            )}>
                <EditarNotificacion notificacionSeleccionado={idNotificacion} refTabla={refTabla}/>
            </Sidebar>
        </div>
    );
}

export default Notificaciones