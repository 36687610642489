import React, { useState, useEffect, useRef } from 'react';
import AdminServiciosAPI from '../servicios/AdminServicios';
import { Button } from 'primereact/button';
import {Checkbox} from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';

const Recursos = (props) => {
    const [listado, setListado] = useState([])
    const [cargando, setCargando] = useState(false)
    const [seleccion, setSeleccion] = useState([])
    const [verConfirmar, setVerConfirmar] = useState(false)
    const mensaje = useRef(null);

    const cargar = async () => {
        setCargando(true)
        const response = await AdminServiciosAPI.listadoRegistros(props.cookies.CRCOOKIEUSUARIO,`meta/${props.cookies.CRCOOKIEMATERIA.id}/recursos`);
        if(response.ok){
            const datosmeta = await response.json();
            if(datosmeta.datos){
                setListado(datosmeta.datos)
            }
        }
        setCargando(false)
    }

    useEffect(() => {
        cargar();
    },[props.cargando])

    const onRecursoChange = (e) => {
        let seleccionados = [...seleccion];
        if(e.checked)
            seleccionados.push(e.value);
        else
            seleccionados.splice(seleccionados.indexOf(e.value), 1);
        setSeleccion(seleccionados);
    }

    const eliminarRecurso = async () => {
        let response = await AdminServiciosAPI.eliminarRegistro(props.cookies.CRCOOKIEUSUARIO,`meta/${props.cookies.CRCOOKIEMATERIA.id}/recursos`,{seleccionados: seleccion});
        if(response.error){
            mensaje.current.show({
                severity: 'warn',
                sticky: true,
                detail: `Lo sentimos tenemos problemas con red, intente en unos minutos.`
            });
        }else{
            const errormsj = await response.json();
            mensaje.current.show({
                severity: response.ok?'info':'warn',
                sticky: true,
                detail: errormsj
            });
            cargar();
        }
    }

    const itemTemplate = (data,i) => {
        return (
            <div className="row mb-2" key={`re-${i}`}>
                <div className="col">
                    <Checkbox value={data} onChange={onRecursoChange} checked={seleccion.includes(data)}></Checkbox>
                    <label className="p-checkbox-label ps-2">{data}</label>
                </div>
            </div>
        );
    }

    return(
        <div>
            <Toast ref={mensaje} />
            <Button icon="pi pi-trash" className='mb-2 p-button-secondary' label='Eliminar' disabled={seleccion.length===0} onClick={()=>setVerConfirmar(true)}></Button>
            <div className="card p-2 globalScroll">
                <div className='listaScroll'>
                    {listado.map((r,i) => {
                        return itemTemplate(r,i);
                    })}
                </div>
            </div>
            <ConfirmDialog visible={verConfirmar} onHide={() => setVerConfirmar(false)} message="¿Confirmar eliminar recursos seleccionados?"
                    header="Confirmación" icon="pi pi-exclamation-triangle" accept={eliminarRecurso} reject={()=>setVerConfirmar(false)} acceptLabel="Si" />
        </div>
    )
}

export default Recursos