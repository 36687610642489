import React, { Component } from 'react';
import AdminServiciosAPI from '../servicios/AdminServicios';
import { Constantes, InputDatoPerfil } from '../comun';
import { InputText } from "primereact/inputtext";
import { SelectButton } from 'primereact/selectbutton';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { BlockUI } from 'primereact/blockui';
import {Password} from 'primereact/password';
import { Dropdown } from 'primereact/dropdown';

class EditarEstudiante extends Component{ 
    constructor(props) {
        super(props);
        this.state = {
            cargando: false,
            guardando: false,
            listaDatosPerfil: null,
            listaTiposEstudiante: null,
            editar: false,
            estado: 1,
            nombres: '',
            apellidos: '',
            usuario: '',
            clave: '',
            idEstudiante: '',
            idMateriaAlumno: '',
            tipo: null,
        };
        this.cargarDatosPerfil = this.cargarDatosPerfil.bind(this);
        this.cargarDatosEstudiante = this.cargarDatosEstudiante.bind(this);
        this.cargarEstudiante = this.cargarEstudiante.bind(this);
        this.onGuardar = this.onGuardar.bind(this);
        this.setValorDato = this.setValorDato.bind(this);
        this.cargarTiposEstudiante = this.cargarTiposEstudiante.bind(this);
    }

    async cargarDatosPerfil() {
        this.setState({cargando: true});
        const response = await AdminServiciosAPI.listadoRegistros(this.props.cookies.CRCOOKIEUSUARIO,`estudiantes/datos/${this.props.cookies.CRCOOKIEMATERIA.id_sitio}`);
        if(response.ok){
            const listaDatosPerfil = await response.json();
            if(listaDatosPerfil.length > 0){
                this.setState({ listaDatosPerfil:listaDatosPerfil }, this.cargarDatosEstudiante);
            }else{
                this.setState({listaDatosPerfil: []}, this.cargarDatosEstudiante)
            }
        }else{
            const error = await response.json();
            console.log('error datos perfil',error);
        }
        this.setState({cargando: false});
    }

    async cargarTiposEstudiante(){
        this.setState({cargando: true});
        const response = await AdminServiciosAPI.listadoRegistros(this.props.cookies.CRCOOKIEUSUARIO,`meta/${this.props.cookies.CRCOOKIEMATERIA.id}/tipoestudiante`);
        if(response.ok){
            const datosmeta = await response.json();
            if(datosmeta.datos){
                this.setState({listaTiposEstudiante: datosmeta.datos}, this.cargarDatosEstudiante);
            }else{
                this.cargarDatosEstudiante();
            }
        }
        this.setState({cargando: false});
    }

    async cargarDatosEstudiante(){
        if(this.state.listaDatosPerfil){
            if(typeof this.props.estudianteSeleccionado!=='undefined' && typeof this.props.estudianteSeleccionado.id !== 'undefined' && !this.state.editar){
                await this.cargarEstudiante();
                this.setState({editar: true});
            }
        }else{
            await this.cargarDatosPerfil();
        }
    }

    async cargarEstudiante(){
        this.setState({cargando: true});
        const response = await AdminServiciosAPI.getEstudiante(this.props.cookies.CRCOOKIEUSUARIO,this.props.estudianteSeleccionado.id);
        if(response.ok){
            let estudiante = await response.json();
            estudiante.perfil.forEach(dato =>{
                const objIndex = this.state.listaDatosPerfil.findIndex((obj => obj.id === dato.id_dato_perfil));
                this.state.listaDatosPerfil[objIndex].valor = dato.valor;
            });
            if(estudiante.datos.datos){
                estudiante.datos.datos = JSON.parse(estudiante.datos.datos);
            }
            this.setState({
                idEstudiante: estudiante.datos.id,
                nombres: estudiante.datos.nombres,
                apellidos: estudiante.datos.apellidos,
                usuario: estudiante.datos.usuario,
                estado: estudiante.datos.id_estado,
                idMateriaAlumno: estudiante.datos.id_materia_alumno,
                listaDatosPerfil: this.state.listaDatosPerfil,
                tipo: estudiante.datos.datos && estudiante.datos.datos.tipo?estudiante.datos.datos.tipo:null
            });
		}else{
            const errorestudiante = await response.json();
            console.log('error al cargar estudiante',errorestudiante);
        }
        this.setState({cargando: false});
    }

    componentDidMount() {
        this.cargarTiposEstudiante();
    }

    async onGuardar () {
        let response;
        this.setState({
            cargando: true,
            guardando: true,
        });
        let datos = {
            id_materia: this.props.cookies.CRCOOKIEMATERIA.id,
            id_sitio: this.props.cookies.CRCOOKIEMATERIA.id_sitio,
            nombres: this.state.nombres,
            apellidos: this.state.apellidos,
            usuario: this.state.usuario,
            clave: this.state.clave,
            id_estado: this.state.estado,
            datos: this.state.tipo?JSON.stringify({tipo: this.state.tipo}):null
        }
        if(this.state.editar){
            datos['id_materia_alumno']=this.state.idMateriaAlumno;
            response = await AdminServiciosAPI.editarRegistro(this.props.cookies.CRCOOKIEUSUARIO,'estudiantes',{datos:datos, perfil: this.state.listaDatosPerfil,  where:{id: this.state.idEstudiante}});
        }else{
            response = await AdminServiciosAPI.nuevoRegistro(this.props.cookies.CRCOOKIEUSUARIO,'estudiantes',{datos:datos, perfil: this.state.listaDatosPerfil});
        }
        if(response.error){
            this.mensaje.show({
                severity: 'warn',
                sticky: true,
                detail: `Lo sentimos tenemos problemas con red, intente en unos minutos.`
            });
        }else{
            const errormsj = await response.json();
            //console.log('guardado estudiante',errormsj);
            let eserror = true;
            if(typeof errormsj.id !== 'undefined'){
                this.setState({
                    idEstudiante: errormsj.id,
                    idMateriaAlumno: errormsj.idmateriaalumno
                });
                eserror = false;
            }
            this.mensaje.show({
                severity: response.ok?'info':'warn',
                sticky: true,
                detail: eserror?errormsj:'Estudiante guardado con éxito'
            });
            response.ok && this.props.refTabla.current.loadLazyData();
            this.setState({editar: response.ok});
        }
        this.setState({
            cargando: false,
            guardando: false,
        });
    }

    setValorDato(valor, index){
        const listaDatosPerfil = this.state.listaDatosPerfil;
        listaDatosPerfil[index].valor=valor;
        this.setState({listaDatosPerfil});
    }

    render(){
        const leftContents = (
            <React.Fragment>
                <Button label="Guardar" loading={this.state.guardando} icon="pi pi-save" className="p-mr-2" onClick={() => {this.onGuardar()}}/>
            </React.Fragment>
        );

        return (
            <BlockUI blocked={this.state.cargando}>
                <Toolbar className="mb-3 toolbaredicion" left={leftContents} />
                <Messages ref={(el) => this.mensaje = el} />
                <div className="mb-3">
                    <label htmlFor="nombresEstudiante" className="form-label">Nombres</label>
                    <InputText id="nombresEstudiante" className="p-d-block form-control" onChange={(e) => this.setState({nombres: e.target.value})} value={this.state.nombres} maxLength="100" required/>
                </div>
                <div className="mb-3">
                    <label htmlFor="apellidosEstudiante" className="form-label">Apellidos</label>
                    <InputText id="apellidosEstudiante" className="p-d-block form-control" onChange={(e) => this.setState({apellidos: e.target.value})} value={this.state.apellidos} maxLength="100" required/>
                </div>
                <div className="row mb-3">
                    <div className="col-md-6">
                        <label htmlFor="usuarioEstudiante" className="form-label block">Usuario</label>
                        <InputText id="usuarioEstudiante" className="p-d-block form-control" onChange={(e) => this.setState({usuario: e.target.value})} value={this.state.usuario} maxLength="100" required/>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="estadoEstudiante" className="form-label d-block">Clave</label>
                        <Password className="p-d-block" value={this.state.clave} onChange={(e) => this.setState({clave: e.target.value})} toggleMask/>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-6">
                        <label htmlFor="estadoEstudiante" className="form-label">Estado</label>
                        <SelectButton value={this.state.estado} options={Constantes.estEstudiante} onChange={(e) => this.setState({estado: e.value})} />
                    </div>
                    {this.state.listaTiposEstudiante && <div className="col-md-6">
                        <label htmlFor="estadoEstudiante" className="form-label d-block">Tipo</label>
                        <Dropdown value={this.state.tipo} options={this.state.listaTiposEstudiante} onChange={(e) => this.setState({tipo: e.value})} optionLabel="nombre" placeholder="Seleccione un tipo" />
                    </div>}
                </div>
                {this.state.listaDatosPerfil && this.state.listaDatosPerfil.map((dato,i) => {
                    return <div className="mb-3" key={dato.id}>
                        <label htmlFor={`dp-${dato.id}`} className="form-label d-block">{dato.nombre}</label>
                        <InputDatoPerfil id={`dp-${dato.id}`} dato={dato} index={i} setValorDato={this.setValorDato}/>
                    </div>
                })}
            </BlockUI>
        );
    }
}

export default EditarEstudiante