import { request } from'./Omni';
import { Config } from '../comun';

const PhpServiciosAPI = {
    getPriv: async (cookieusuario,pagina) => {
        const _url = `${Config.apiPhp}/${pagina}`;
        return request(_url, {
          method: "GET",
          headers: {"Content-Type": "application/json", "authorization":cookieusuario},
        });
    },
    postPriv: async (cookieusuario,pagina,parametros) => {
        const _url = `${Config.apiPhp}/${pagina}`;
        return request(_url, {
          method: "POST",
          headers: {"Content-Type": "application/json", "authorization":cookieusuario},
          body: JSON.stringify(parametros),
        });
    },
}

export default PhpServiciosAPI;