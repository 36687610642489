import React, { useState, useRef } from 'react';
import { useCookies } from 'react-cookie';
import AdminServiciosAPI from '../servicios/AdminServicios';
import Tabla from '../core/Tabla';
import { Sidebar } from 'primereact/sidebar';
import EditarTutoria from '../materia/EditarTutoria';
import EditarTutores from '../materia/EditarTutores';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

const Tutoria = () => { 
    const [cookies] = useCookies(['user']);
    const [idTutoria, setIdTutoria] = useState(null);
    const [editarTutoria, setEditarTutoria] = useState(false);
    const [editarTutores, setEditarTutores] = useState(false);
    const refTabla = useRef(null);
    const mensaje = useRef(null);
    let where;

    const columnas = [
        {field: 'nombre_tutor', header: 'Tutor', sortable:true, filter:true},
        {field: 'nombres', header: 'Nombres', sortable:true, filter:true},
        {field: 'apellidos', header: 'Apellidos', sortable:true, filter:true},
        {field: 'pregunta', header: 'Pregunta', sortable:true, filter:true},
        {field: 'respuestacorta', header: 'Respuesta', sortable:true, filter:true},
    ];

    where = {where: {id_materia: cookies.CRCOOKIEMATERIA.id}};
    if(cookies.CRUSUARIO.tipo){
        where = {where: {id_materia: cookies.CRCOOKIEMATERIA.id, id_tutor: cookies.CRUSUARIO.id}};
    }
    
    function onEditar(idtutoria){
        setIdTutoria(idtutoria);
        setEditarTutoria(true);
    }

    async function onDelete(seleccionados){
        let response = await AdminServiciosAPI.eliminarRegistro(cookies.CRCOOKIEUSUARIO,'tutoria',{seleccionados: seleccionados});
        if(response.error){
            mensaje.current.show({
                severity: 'warn',
                sticky: true,
                detail: `Lo sentimos tenemos problemas con red, intente en unos minutos.`
            });
        }else{
            const errormsj = await response.json();
            mensaje.current.show({
                severity: response.ok?'info':'warn',
                sticky: true,
                detail: errormsj
            });
            response.ok && refTabla.current.loadLazyData();
        }
    }

    const onCerrar = () => {
        setIdTutoria(null);
        setEditarTutoria(false);
    }

    const onCerrarTutores = () => {
        setEditarTutores(false);
    }

    const onTutores = () => {
        setEditarTutores(true);
    }

    const headerTabla = (
        <div className="table-header">
            <h5 className="p-m-0">Administración de Tutorias</h5>
        </div>
    );

    const opcionesTabla = (
        <React.Fragment>
            {!cookies.CRUSUARIO.tipo && <Button label="Tutores" icon="pi pi-user-plus" className="p-button-success me-2" onClick={() => {onTutores()}}  />}
        </React.Fragment>
    );

    return (
        <div>
            <Toast ref={mensaje} />
            <Tabla ref={refTabla} header={headerTabla} cookies={cookies} nombreTabla='tutoria' columnas={columnas} where={where} onEditar={onEditar} onDelete={onDelete} noNuevo rightToolbarTemplate={opcionesTabla} />
            <Sidebar visible={editarTutoria} fullScreen onHide={() => onCerrar()} icons={() => (
                <React.Fragment>
                    <h5 className="text-center">Editar Tutoria</h5>
                </React.Fragment>
            )}>
                <EditarTutoria tutoriaSeleccionado={idTutoria} refTabla={refTabla}/>
            </Sidebar>
            <Sidebar position="right" className="ui-sidebar-lg" visible={editarTutores} onHide={() => onCerrarTutores()} icons={() => (
                <React.Fragment>
                    <h5 className="text-center">Tutores</h5>
                </React.Fragment>
            )}>
                <EditarTutores cookies={cookies}/>
            </Sidebar>
        </div>
    );
}

export default Tutoria