import React, { useState, useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import AdminServiciosAPI from '../servicios/AdminServicios';
import { Constantes } from '../comun';
import Lecciones from './Lecciones';
import { InputArchivo } from '../comun';
import EditarEvaluacion from './EditarEvaluacion';
import { InputText } from "primereact/inputtext";
import { InputNumber } from 'primereact/inputnumber';
import { SelectButton } from 'primereact/selectbutton';
import { TabView,TabPanel } from 'primereact/tabview';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { BlockUI } from 'primereact/blockui';
import { InputMask } from 'primereact/inputmask';
import Config from '../comun/Config';
import EditarEnlaces from '../comun/EditarEnlaces';

const EditarModulo = ({moduloSeleccionado, refTabla}) => { 
    const [cookies] = useCookies(['user']);
    const [titulo, setTitulo] = useState('');
    const [orden,  setOrden] = useState(1);
    const [estado,  setEstado] = useState(Constantes.estadosPublicado[1]);
    const [lecciones, setLecciones] = useState([]);
    const [evaluacion, setEvaluacion] = useState([]);
    const [enlaces, setEnlaces] = useState([]);
    const [puntaje, setPuntaje] = useState(null);
    const [intentos, setIntentos] = useState(1);
    const mensaje = useRef(null);
    const [cargando, setCargando] = useState(false);
    const [guardando, setGuardando] = useState(false);
    const [editar, setEditar] = useState(false);
    const [imagenModulo, setImagenModulo] = useState(null);
    const [tiempoEvaluacion, setTiempoEvaluacion] = useState(0);
    const [urlVideoModulo, setUrlVideoModulo] = useState('');
    const [clave, setClave] = useState('');
    const [validarAnterior, setValidarAnterior] = useState(Constantes.estadoAfirmacion[1]);

    useEffect( () => {
        if(typeof moduloSeleccionado.orden !== 'undefined'){
            cargarModulo();
            setOrden(moduloSeleccionado.orden);
            setEditar(true);
        }
    }, []);

    async function cargarModulo(){
        setCargando(true);
        const response = await AdminServiciosAPI.getModulo(cookies.CRCOOKIEUSUARIO,cookies.CRCOOKIEMATERIA.id,moduloSeleccionado.orden);
        if(response.ok){
            const modulo = await response.json();
            setTitulo(modulo.titulo);
            setEstado(modulo.estado);
            setLecciones(modulo.lecciones);
            setEvaluacion(modulo.evaluacion);
            setIntentos(modulo.datos.intentos);
            setPuntaje(modulo.datos.puntaje);
            setImagenModulo(modulo.datos.imagen);
            setTiempoEvaluacion(modulo.datos.tiempoEvaluacion);
            setClave(modulo.datos.clave);
            if(modulo.datos.enlaces)
            {
                setEnlaces(modulo.datos.enlaces);
            }
            if(modulo.datos.video){
                setUrlVideoModulo(modulo.datos.video);
            }
            if(modulo.datos.validarAnterior){
                setValidarAnterior(modulo.datos.validarAnterior)
            }
		}
        setCargando(false);
    }

    function modificarLecciones(listadoNuevo){
        setLecciones(listadoNuevo);
    }

    function modificarEvaluacion(listadoNuevo){
        setEvaluacion(listadoNuevo);
    }

    function modificarEnlaces(listadoNuevo){
        setEnlaces(listadoNuevo);
    }

    function modificarImagenModulo(imagen){
        setImagenModulo(imagen);
    }

    const leftContents = (
        <React.Fragment>
            <Button label="Guardar" loading={guardando} icon="pi pi-save" className="p-mr-2" onClick={() => {onGuardar()}}/>
        </React.Fragment>
    );

    const onGuardar = async () => {
        let response;
        setGuardando(true);
        setCargando(true);
        const datos = {
            id_materia: cookies.CRCOOKIEMATERIA.id,
            orden: orden,
            lecciones: lecciones,
            titulo: titulo,
            estado: estado,
            evaluacion: evaluacion,
            datos: {
                imagen:imagenModulo,
                intentos: intentos,
                puntaje: puntaje,
                enlaces: enlaces,
                tiempoEvaluacion: tiempoEvaluacion,
                video: urlVideoModulo,
                clave: clave,
                validarAnterior: validarAnterior
            }
        }
        if(editar){
            response = await AdminServiciosAPI.editarRegistro(cookies.CRCOOKIEUSUARIO,'modulos',datos);
        }else{
            response = await AdminServiciosAPI.nuevoRegistro(cookies.CRCOOKIEUSUARIO,'modulos',datos);
        }
        if(response.error){
            mensaje.current.show({
                severity: 'warn',
                sticky: true,
                detail: `Lo sentimos tenemos problemas con red, intente en unos minutos.`
            });
        }else{
            const errormsj = await response.json();
            mensaje.current.show({
                severity: response.ok?'info':'warn',
                sticky: true,
                detail: errormsj
            });
            if(response.ok && imagenModulo && imagenModulo.name){
                setImagenModulo(imagenModulo.name);
            }
            if(!editar){
                refTabla.current.loadLazyData();
                setEditar(response.ok);
            }else if(moduloSeleccionado.titulo!==titulo){
                refTabla.current.loadLazyData();
            }
            window.scrollTo(0, 0);
        }
        setCargando(false);
        setGuardando(false);
    }


    return (
        <BlockUI blocked={cargando}>
            <Toolbar className="mb-3 toolbaredicion" left={leftContents} />
            <Messages ref={mensaje} />
            <div className="mb-3">
                <label htmlFor="tituloModulo" className="form-label">Título del Módulo</label>
                <InputText id="tituloModulo" className="p-d-block form-control" onChange={(e) => setTitulo(e.target.value)} value={titulo} maxLength="200" required/>
            </div>
            <div className="row mb-3">
                <div className="col-md-4">
                    <label htmlFor="ordenModulo" className="form-label block">Orden</label>
                    <InputNumber id="ordenModulo" value={orden} onValueChange={(e) => setOrden(e.value)} showButtons min={1} max={100} disabled={editar} />
                </div>
                <div className="col-md-4">
                    <label htmlFor="estadoModulo" className="form-label">Estado</label>
                    <SelectButton value={estado} options={Constantes.estadosPublicado} onChange={(e) => setEstado(e.value)} />
                </div>
                <div className="col-md-4">
                    <label htmlFor="videoModulo" className="form-label">Video</label>
                    <InputText id="videoModulo" className="p-d-block form-control" value={urlVideoModulo} onChange={(e) => setUrlVideoModulo(e.target.value)} />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-4">
                    <label htmlFor="puntosEvaModulo" className="form-label block">Puntaje Evaluación</label>
                    <InputMask id='puntosEvaModulo' mask="99/999" value={puntaje} onChange={(e) => setPuntaje(e.value)} placeholder="xx/100"></InputMask>
                </div>
                <div className="col-md-4">
                    <label htmlFor="intentosEvaModulo" className="form-label d-block">Intentos Evaluación</label>
                    <InputNumber id="intentosEvaModulo" value={intentos} onValueChange={(e) => setIntentos(e.value)} showButtons min={1} max={20} />
                </div>
                <div className="col-md-4">
                    <label htmlFor="tiempoEvaModulo" className="form-label d-block">Duración Evaluación (min)</label>
                    <InputNumber id="tiempoEvaModulo" value={tiempoEvaluacion} onValueChange={(e) => setTiempoEvaluacion(e.value)} showButtons min={1} max={300} />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-4">
                    <label htmlFor="claveModulo" className="form-label">Clave</label>
                    <InputText id="claveModulo" className="p-d-block form-control w-25" value={clave} onChange={(e) => setClave(e.target.value)} />
                </div>
                <div className="col-md-4">
                    <label htmlFor="estadoModulo" className="form-label">Validar módulo anteior</label>
                    <SelectButton value={validarAnterior} options={Constantes.estadoAfirmacion} onChange={(e) => setValidarAnterior(e.value)} />
                </div>
            </div>
            <TabView>
                <TabPanel header="Lecciones">
                    <Lecciones listadoLecciones={lecciones} setLecciones={modificarLecciones} />
                </TabPanel>
                <TabPanel header="Evaluación">
                    <EditarEvaluacion listaPreguntas={evaluacion} modificarEvaluacion={modificarEvaluacion} />
                </TabPanel>
                <TabPanel header="Personalización">
                    <p>Por favor cargue una imágen jpg de 400x220 px</p>
                    <InputArchivo valor={imagenModulo} dato={imagenModulo && typeof imagenModulo === 'string' && {url:`${Config.cloudFront}${cookies.CRCOOKIEMATERIA.id}/modulos/${orden}/${imagenModulo}`,mime:'IMAGEN'}}  id={`imagen_${Math.floor(Math.random() * 100)}`} setValorDato={modificarImagenModulo} />
                </TabPanel>
                <TabPanel header="Enlaces">
                    <EditarEnlaces listaEnlaces={enlaces} modificarEnlaces={modificarEnlaces} />
                </TabPanel>
            </TabView>
        </BlockUI>
    );
}

export default EditarModulo