import React, { useState, useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import AdminServiciosAPI from '../servicios/AdminServicios';
import { InputTextarea } from 'primereact/inputtextarea';
import { BlockUI } from 'primereact/blockui';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';

const EditarTutoria = (props) => {
    const [cookies] = useCookies(['user']);
    const [respuesta, setRespuesta] = useState('');
    const [tutoria, setTutoria] = useState(null);
    const mensaje = useRef(null);
    const [cargando, setCargando] = useState(false);
    const [guardando, setGuardando] = useState(false);

    useEffect( () => {
        async function cargarTutoria(){
            setCargando(true);
            const response = await AdminServiciosAPI.listadoRegistros(cookies.CRCOOKIEUSUARIO,`tutoria/${props.tutoriaSeleccionado.id}`);
            if(response.ok){
                const datost = await response.json();
                setTutoria(datost);
                if(datost.respuesta){
                    setRespuesta(datost.respuesta);
                }
            }
            setCargando(false);
        }
        cargarTutoria();
    }, []);

    const onGuardar = async() => {
        setGuardando(true);
        setCargando(true);
        const response = await AdminServiciosAPI.editarRegistro(cookies.CRCOOKIEUSUARIO,'tutoria',{datos:{respuesta: respuesta}, where:{id: tutoria.id}});
        if(response.error){
            mensaje.current.show({
                severity: 'warn',
                sticky: true,
                detail: `Lo sentimos tenemos problemas con red, intente en unos minutos.`
            });
        }else{
            const errormsj = await response.json();
            mensaje.current.show({
                severity: response.ok?'info':'warn',
                sticky: true,
                detail: errormsj
            });
            response.ok && props.refTabla.current.loadLazyData();
        }
        setCargando(false);
        setGuardando(false);
    }

    const leftContents = (
        <React.Fragment>
            <Button label="Guardar" loading={guardando} icon="pi pi-save" className="p-mr-2" onClick={() => {onGuardar()}}/>
        </React.Fragment>
    );

    return (
        <BlockUI blocked={cargando}>
            <Toolbar className="mb-3 toolbaredicion" left={leftContents} />
            <Messages ref={mensaje} />
            {tutoria && <div><div className="mb-3">
                <label className="form-label">Tutor</label>
                <p>{props.tutoriaSeleccionado && props.tutoriaSeleccionado.nombre_tutor}</p>
            </div>
            <div className="mb-3">
                <label htmlFor="pregunta" className="form-label">Pregunta</label>
                <p>{tutoria.pregunta}</p>
            </div>
            <div className="mb-3">
                <label htmlFor="respuesta" className="form-label">Respuesta</label>
                <InputTextarea itemID='respuesta' className='w-100' value={respuesta} onChange={(e) => setRespuesta(e.target.value)} rows={7} cols={30} />
            </div></div>}
        </BlockUI>
    )
}

export default EditarTutoria