import React, { useRef, useState } from 'react';
import { FileUpload } from 'primereact/fileupload';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';

const InputArchivo = ({valor,dato,id,setValorDato}) => {
    const [deleteSelectedDialog, setDeleteSelectedDialog] = useState(false);
    const inputFile = useRef();
    const limpiarArchivo = () => {
        inputFile.current.clear();
    }

    const confirmDeleteSelected = () => {
        setDeleteSelectedDialog(true);
    }

    const hideDeleteSelected = () => {
        setDeleteSelectedDialog(false);
    }

    const deleteSelectedDialogFooter = () => (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteSelected} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={onEliminar} />
        </React.Fragment>
    );

    const onEliminar = () => {
        dato.url=null;
        setValorDato(null);
        hideDeleteSelected();
    }

    const onSelect = (e) => {
        const [file] = e.files;
        const fileReader = new FileReader();
        fileReader.onloadend = (e) => {
            const imagenFinal = {
                name:id+'.'+file.name.substring(file.name.lastIndexOf('.')+1),
                type:file.type,
                file:e.target.result.split(",")[1]
            };
            setValorDato(imagenFinal);
        };
        fileReader.readAsDataURL(file);
    }

    const leftContents = (
        <span>
            {dato && <Button label="Eliminar" icon="pi pi-trash" className="p-button-danger" disabled={dato.url==='undefined'} onClick={() => confirmDeleteSelected()} />}
        </span>
    );

    const rightContents = (
        <div className="p-inputgroup">
            <FileUpload ref={inputFile} mode="basic" name={id} customUpload onSelect={onSelect} uploadHandler={() => {return;}}  accept="image/*" maxFileSize="4194304"></FileUpload>
            <Button icon="pi pi-times" className="p-button-secondary" onClick={() => {limpiarArchivo()}} title="Cancelar" disabled={valor && typeof valor.name === 'undefined'}/>
        </div>
    );

    const header = (
        <Toolbar left={leftContents} right={rightContents} />
    );

    return (
        <div>
            <Card header={header} className="inputArchivo">
                {dato && dato.url && dato.mime==='IMAGEN' && <img src={dato.url} />}
            </Card>
            {dato && <Dialog visible={deleteSelectedDialog} style={{ width: '450px' }} header="Confirmación" modal footer={deleteSelectedDialogFooter} onHide={hideDeleteSelected}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {dato.url && <span>Debe dar en Guardar para ejecutar cualquier acción. ¿Está seguro de eliminar el archivo?</span>}
                </div>
            </Dialog>}
        </div>
    );
}

export default InputArchivo;