import React, { useState, useEffect, useRef } from 'react';
import AdminServiciosAPI from '../servicios/AdminServicios';
import { ListBox } from 'primereact/listbox';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { Messages } from 'primereact/messages';
import { BlockUI } from 'primereact/blockui';
import { obtenerIdNuevo } from '../servicios/Omni';

const TipoEstudiante = ({cookies}) => { 
    const [lista, setLista] = useState([]);
    const [editarDato, setEditarDato] = useState(false);
    const [datoSeleccionado, setDatoSeleccionado] = useState(null);
    const [deleteSelectedDialog, setDeleteSelectedDialog] = useState(false);
    const [nombre, setNombre] = useState('');
    const [cargando, setCargando] = useState(false);
    const mensaje = useRef(null);
    
    useEffect( () => {
        console.log('lista 1',lista)
        const cargarTiposEstudiante = async () => {
            setCargando(true);
            const response = await AdminServiciosAPI.listadoRegistros(cookies.CRCOOKIEUSUARIO,`meta/${cookies.CRCOOKIEMATERIA.id}/tipoestudiante`);
            if(response.ok){
                const listaDatos = await response.json();
                if(listaDatos.datos){
                    setLista(listaDatos.datos);
                }
            }else{
                const error = await response.json();
                console.log('error cursos',error);
            }
            setCargando(false);
        }
        cargarTiposEstudiante();
    }, []);

    const guardarTipoEstudiante = async () => {
        setCargando(true);
        console.log('lista 2',lista)
        const response = await AdminServiciosAPI.nuevoRegistro(cookies.CRCOOKIEUSUARIO,'meta',{id_materia:cookies.CRCOOKIEMATERIA.id, tipo:'tipoestudiante',datos: lista});
        if(response.error){
            mensaje.current.show({
                severity: 'warn',
                sticky: true,
                detail: `Lo sentimos tenemos problemas con red, intente en unos minutos.`
            });
        }else{
            mensaje.current.show({
                severity: 'info',
                sticky: true,
                detail: 'Tipo guardado con éxito'
            });
            limpiarDato();
        }
        setCargando(false);
    }

    const onIngresarItem = async () => {
        let idNuevo = obtenerIdNuevo(lista)
        lista.push({id: idNuevo, nombre: nombre});
        await guardarTipoEstudiante();
    }

    const onEditarItem = async () => {
        const objIndex = lista.findIndex((obj => obj.id === datoSeleccionado.id));
        datoSeleccionado.nombre = nombre;
        lista[objIndex]=datoSeleccionado;
        await guardarTipoEstudiante();
    }

    const onEliminarItem = async () => {
        const objIndex = lista.findIndex((obj => obj.id === datoSeleccionado.id));
        lista.splice(objIndex,1);
        await guardarTipoEstudiante();
        hideDeleteSelected();
    }

    function limpiarDato(){
        setNombre('');
        setEditarDato(false);
    }

    const onSeleccionDato = (item) => {
        setEditarDato(true);
        setNombre(item.nombre);
        setDatoSeleccionado(item);
    }

    const itemTemplate = (option) => {
        return (
            <div className="row" onClick={() => onSeleccionDato(option)}>
                <div className="col-md-2">
                    id: {option.id}
                </div>
                <div className="col-md-8">
                    {option.nombre}
                </div>
            </div>
        );
    }

    const confirmDeleteSelected = () => {
        setDeleteSelectedDialog(true);
    }

    const hideDeleteSelected = () => {
        setDeleteSelectedDialog(false);
    }

    const deleteSelectedDialogFooter = () => (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteSelected} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={onEliminarItem} />
        </React.Fragment>
    );

    return (
        <BlockUI blocked={cargando}>
            <div className="card">
                <div className="p-col-12 p-md-3">
                    <Messages ref={mensaje} />
                    <label htmlFor="tituloModulo" className="form-label">{editarDato?'Editar':'Nuevo'} Tipo:</label>
                    {editarDato?<div className="p-inputgroup mb-3">
                        <InputText value={nombre} onChange={(e) => setNombre(e.target.value)}/>
                        <Button icon="pi pi-save" className="p-button-success" onClick={() => onEditarItem()} title="Editar"/>
                        <Button icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} title="Eliminar"/>
                        <Button icon="pi pi-times" className="p-button-secondary" onClick={() => limpiarDato()} title="Nuevo"/>
                    </div>:<div className="p-inputgroup mb-3">
                        <InputText value={nombre} onChange={(e) => setNombre(e.target.value)}/>
                        <Button icon="pi pi-plus" className="p-button-info" onClick={() => onIngresarItem()} title="Añadir Tipo"/>
                    </div>}
                    <ListBox className="w-100" value={datoSeleccionado} options={lista} onChange={(e) => setDatoSeleccionado(e.value)} filter optionLabel="nombre"
                        itemTemplate={itemTemplate} style={{ width: '15rem' }} listStyle={{ maxHeight: '250px' }} />
                    <Dialog visible={deleteSelectedDialog} style={{ width: '450px' }} header="Confirmación" modal footer={deleteSelectedDialogFooter} onHide={hideDeleteSelected}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                            {editarDato && <span>Debe dar en Guardar para ejecutar cualquier acción. ¿Está seguro de eliminar el dato "{datoSeleccionado.nombre}"?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </BlockUI>
    );
}

export default TipoEstudiante