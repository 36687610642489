import React, { Component } from 'react';
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
import { OrderList } from 'primereact/orderlist';
import { Dialog } from 'primereact/dialog';
import { obtenerIdNuevo } from '../servicios/Omni';

const enlaceInicial = {
    texto: '',
    url: ''
}

class EditarEnlaces extends Component{
    constructor(props) {
        super(props);
        //console.log('enlaces 2',props.listaEnlaces)
        this.state = {
            editarEnlace: false,
            listaEnlaces: props.listaEnlaces?props.listaEnlaces:[],
            deleteSelectedDialog: false,
            enlaceSeleccionado: JSON.parse(JSON.stringify(enlaceInicial)),
        }
        this.onEditarEnlace = this.onEditarEnlace.bind(this);
        this.hideDeleteSelectedDialog = this.hideDeleteSelectedDialog.bind(this);
        this.onEliminarEnlace = this.onEliminarEnlace.bind(this);
        this.limpiarEnlace = this.limpiarEnlace.bind(this);
        this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
        this.onCambiaOrden = this.onCambiaOrden.bind(this);
        this.itemTemplate = this.itemTemplate.bind(this);
        this.onSeleccionarEnlace = this.onSeleccionarEnlace.bind(this);
        this.onIngresarEnlace = this.onIngresarEnlace.bind(this);
        this.actualizarEnlaces = this.actualizarEnlaces.bind(this);
    }

    actualizarEnlaces(lista){
        this.setState({listaEnlaces: lista});
    }

    onIngresarEnlace(){
        this.state.enlaceSeleccionado.id=obtenerIdNuevo(this.state.listaEnlaces)
        this.state.listaEnlaces.push(this.state.enlaceSeleccionado);
        this.props.modificarEnlaces(this.state.listaEnlaces);
        this.setState({listaEnlaces: this.state.listaEnlaces}, this.limpiarEnlace);
    }

    onEditarEnlace(){
        const objIndex = this.state.listaEnlaces.findIndex((obj => obj.id === this.state.enlaceSeleccionado.id));
        this.state.listaEnlaces[objIndex]=this.state.enlaceSeleccionado;
        this.setState({listaEnlaces: this.state.listaEnlaces});
        this.props.modificarEnlaces(this.state.listaEnlaces);
        this.limpiarEnlace();
    }

    onEliminarEnlace(){
        const objIndex = this.state.listaEnlaces.findIndex((obj => obj.id === this.state.enlaceSeleccionado.id));
        this.state.listaEnlaces.splice(objIndex,1);
        this.setState({listaEnlaces: this.state.listaEnlaces});
        this.props.modificarEnlaces(this.state.listaEnlaces);
        this.hideDeleteSelectedDialog();
        this.limpiarEnlace();
    }

    onCambiaOrden(listaOrdenada){
        this.setState({listaEnlaces: listaOrdenada});
        this.props.modificarEnlaces(listaOrdenada);
    }

    onSeleccionarEnlace(item){
        this.setState({enlaceSeleccionado: item, editarEnlace:true});
    }

    limpiarEnlace(){
        this.setState({enlaceSeleccionado: JSON.parse(JSON.stringify(enlaceInicial))});
        this.setState({editarEnlace: false});
    }

    confirmDeleteSelected(){
        this.setState({deleteSelectedDialog:true});
    }

    hideDeleteSelectedDialog(){
        this.setState({deleteSelectedDialog:false});
    }

    itemTemplate(item){
        return (
            <div className="row" onClick={() => this.onSeleccionarEnlace(item)}>
                <div className="col-md-8">
                    <h6>{item.texto}</h6>
                </div>
            </div>
        );
    }

    render(){
        const deleteSelectedDialogFooter = (<React.Fragment>
                <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteSelectedDialog} />
                <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={this.onEliminarEnlace} />
            </React.Fragment>
        );

        return(<div className="orderlist-demo">
            <div className="card">
                <div className="p-col-12 p-md-3">
                    <label htmlFor="textoEnlace" className="form-label">Nombre {this.state.editarEnlace?'editar':'nuevo'} Enlace:</label>
                    <InputText value={this.state.enlaceSeleccionado.texto} onChange={(e) => this.setState({enlaceSeleccionado: {...this.state.enlaceSeleccionado, texto: e.target.value}})} id="textoEnlace" className="w-100" required/>
                    <label className="form-label w-100 mt-1" htmlFor="urlEnlace">Url:</label>
                    <InputText value={this.state.enlaceSeleccionado.url} onChange={(e) => this.setState({enlaceSeleccionado: {...this.state.enlaceSeleccionado, url: e.target.value}})} id="urlEnlace" className="w-100" required/>
                    {this.state.editarEnlace?<div className="text-center"><Button icon="pi pi-save" label="Editar Enlace" className="p-button-success me-2" onClick={() => {this.onEditarEnlace()}} title="Editar Enlace"/>
                        <Button icon="pi pi-trash" className="p-button-danger me-2" label="Eliminar Enlace" onClick={() => this.confirmDeleteSelected()} title="Eliminar Enlace"/>
                        <Button icon="pi pi-times" className="p-button-secondary" onClick={() => this.limpiarEnlace()} title="Nueva Enlace"/>
                    </div>:<div className="text-center"><Button icon="pi pi-save" className="p-button-info" onClick={() => {this.onIngresarEnlace()}} label="Añadir Enlace" title="Añadir Enlace"/></div>}
                    <OrderList className="mt-3" value={this.state.listaEnlaces} header="Listado de Enlaces" dragdrop listStyle={{height:'auto'}} dataKey="id"
                        itemTemplate={this.itemTemplate} onChange={(e) => this.onCambiaOrden(e.value)}></OrderList>
                    <Dialog visible={this.state.deleteSelectedDialog} style={{ width: '450px' }} header="Confirmación" modal footer={deleteSelectedDialogFooter} onHide={this.hideDeleteSelectedDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                            {this.state.editarEnlace && <span>Debe dar en Guardar para ejecutar cualquier acción. ¿Está seguro de eliminar la enlace "{this.state.enlaceSeleccionado.texto}"?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>);
    }
}

export default EditarEnlaces