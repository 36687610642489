import React, { useState, useEffect, useRef } from 'react';
import AdminServiciosAPI from '../servicios/AdminServicios';
import { Constantes } from '../comun';
import { getLabelfromValue } from'../servicios/Omni';
import { ListBox } from 'primereact/listbox';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Messages } from 'primereact/messages';
import { BlockUI } from 'primereact/blockui';

const DatosPerfil = ({cookies}) => { 
    const [lista, setLista] = useState([]);
    const [editarDato, setEditarDato] = useState(false);
    const [datoSeleccionado, setDatoSeleccionado] = useState(null);
    const [deleteSelectedDialog, setDeleteSelectedDialog] = useState(false);
    const [nombre, setNombre] = useState('');
    const [tipo, setTipo] = useState('t');
    const [cargando, setCargando] = useState(false);
    const mensaje = useRef(null);
    
    useEffect( () => {
        const cargarDatosPerfil = async () => {
            setCargando(true);
            const response = await AdminServiciosAPI.listadoRegistros(cookies.CRCOOKIEUSUARIO,`estudiantes/datos/${cookies.CRCOOKIEMATERIA.id_sitio}`);
            if(response.ok){
                const listaDatos = await response.json();
                if(listaDatos.length > 0){
                    setLista(listaDatos);
                }
            }else{
                const error = await response.json();
                console.log('error cursos',error);
            }
            setCargando(false);
        }
        cargarDatosPerfil();
    }, []);

    const ingresarDato = async () => {
        const nuevoDato = {
            nombre:nombre,
            tipo:tipo,
            id_sitio: cookies.CRCOOKIEMATERIA.id_sitio
        }
        setCargando(true);
        const response = await AdminServiciosAPI.nuevoRegistro(cookies.CRCOOKIEUSUARIO,'estudiantes/datos',nuevoDato);
        if(response.error){
            mensaje.current.show({
                severity: 'warn',
                sticky: true,
                detail: `Lo sentimos tenemos problemas con red, intente en unos minutos.`
            });
        }else{
            const errormsj = await response.json();
            if(!isNaN(errormsj)){
                nuevoDato['id']=errormsj;
            }
            mensaje.current.show({
                severity: response.ok?'info':'warn',
                sticky: true,
                detail: isNaN(errormsj)?errormsj:'Dato añadido con éxito'
            });
            lista.push(nuevoDato);
            setLista(lista);
            limpiarDato();
        }
        setCargando(false);
    }

    const editaDato = async () => {
        datoSeleccionado.nombre = nombre;
        datoSeleccionado.tipo = tipo;
        setCargando(true);
        const response = await AdminServiciosAPI.editarRegistro(cookies.CRCOOKIEUSUARIO,'estudiantes/datos',{datos:datoSeleccionado, where:{id: datoSeleccionado.id}});
        if(response.error){
            mensaje.current.show({
                severity: 'warn',
                sticky: true,
                detail: `Lo sentimos tenemos problemas con red, intente en unos minutos.`
            });
        }else{
            const errormsj = await response.json();
            mensaje.current.show({
                severity: response.ok?'info':'warn',
                sticky: true,
                detail: isNaN(errormsj)?errormsj:'Dato guardado con éxito'
            });
            const objIndex = lista.findIndex((obj => obj.id === datoSeleccionado.id));
            lista[objIndex] = datoSeleccionado;
            setLista(lista);
            limpiarDato();
        }
        setCargando(false);
    }

    const eliminarDato = async () => {
        setCargando(true);
        const response = await AdminServiciosAPI.eliminarRegistro(cookies.CRCOOKIEUSUARIO,'estudiantes/datos',{id: datoSeleccionado.id});
        if(response.error){
            mensaje.current.show({
                severity: 'warn',
                sticky: true,
                detail: `Lo sentimos tenemos problemas con red, intente en unos minutos.`
            });
        }else{
            const errormsj = await response.json();
            mensaje.current.show({
                severity: response.ok?'info':'warn',
                sticky: true,
                detail: response.ok?'Se eliminó correctamente':errormsj,
            });
            const objIndex = lista.findIndex((obj => obj.id === datoSeleccionado.id));
            lista.splice(objIndex,1);
            limpiarDato();
            hideDeleteSelected();
        }
        setCargando(false);
    }

    function limpiarDato(){
        setNombre('');
        setTipo('t')
        setEditarDato(false);
    }

    const onSeleccionDato = (item) => {
        setEditarDato(true);
        setNombre(item.nombre);
        setTipo(item.tipo);
        setDatoSeleccionado(item);
    }

    const itemTemplate = (option) => {
        return (
            <div className="row" onClick={() => onSeleccionDato(option)}>
                <div className="col-md-2">
                    id_{option.id}
                </div>
                <div className="col-md-7">
                    {option.nombre}
                </div>
                <div className="col-md-3">
                    <strong>Tipo:</strong> {getLabelfromValue(Constantes.tipoDatoPerfil,option.tipo)}
                </div>
            </div>
        );
    }

    const confirmDeleteSelected = () => {
        setDeleteSelectedDialog(true);
    }

    const hideDeleteSelected = () => {
        setDeleteSelectedDialog(false);
    }

    const deleteSelectedDialogFooter = () => (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteSelected} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={eliminarDato} />
        </React.Fragment>
    );

    return (
        <BlockUI blocked={cargando}>
            <div className="card">
                <div className="p-col-12 p-md-3">
                    <Messages ref={mensaje} />
                    <label htmlFor="tituloModulo" className="form-label">{editarDato?'Editar':'Nuevo'} Dato:</label>
                    {editarDato?<div className="p-inputgroup mb-3">
                        <InputText value={nombre} onChange={(e) => setNombre(e.target.value)}/>
                        <Dropdown value={tipo} options={Constantes.tipoDatoPerfil} onChange={(e)=>{setTipo(e.value)}} optionLabel="label" placeholder="Seleccionar tipo" />
                        <Button icon="pi pi-save" className="p-button-success" onClick={() => {editaDato()}} title="Editar"/>
                        <Button icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} title="Eliminar"/>
                        <Button icon="pi pi-times" className="p-button-secondary" onClick={() => {limpiarDato()}} title="Nuevo"/>
                    </div>:<div className="p-inputgroup mb-3">
                        <InputText value={nombre} onChange={(e) => setNombre(e.target.value)}/>
                        <Dropdown value={tipo} options={Constantes.tipoDatoPerfil} onChange={(e)=>{setTipo(e.value)}} optionLabel="label" placeholder="Seleccionar tipo" />
                        <Button icon="pi pi-plus" className="p-button-info" onClick={() => {ingresarDato()}} title="Añadir Dato"/>
                    </div>}
                    <ListBox className="w-100" value={datoSeleccionado} options={lista} onChange={(e) => setDatoSeleccionado(e.value)} filter optionLabel="nombre"
                        itemTemplate={itemTemplate} style={{ width: '15rem' }} listStyle={{ maxHeight: '250px' }} />
                    <Dialog visible={deleteSelectedDialog} style={{ width: '450px' }} header="Confirmación" modal footer={deleteSelectedDialogFooter} onHide={hideDeleteSelected}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                            {editarDato && <span>Debe dar en Guardar para ejecutar cualquier acción. ¿Está seguro de eliminar el dato "{datoSeleccionado.nombre}" ya no saldrá el dato en los alumnos?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </BlockUI>
    );
}

export default DatosPerfil