import React from 'react';
import { Link } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { Constantes } from '../comun';

const MenuLeft = () => {  
    const [cookies] = useCookies(['user']);

    const MenuAdmin = () => {
        return <ul>
            <li className="submenu">
                <Link to="/"><i className="pi pi-dashboard" aria-hidden="true"></i> <span>Curso</span> <span className="menu-arrow"></span></Link>
            </li>
            <li className="submenu">
                <Link to="/modulos"><i className="pi pi-book" aria-hidden="true"></i> <span>Módulos</span> <span className="menu-arrow"></span></Link>
            </li>
            <li className="submenu">
                <Link to="/temas"><i className="pi pi-list" aria-hidden="true"></i> <span>Temas</span> <span className="menu-arrow"></span></Link>
            </li>
            <li className="submenu">
                <Link to="/estudiantes"><i className="pi pi-users" aria-hidden="true"></i> <span>Estudiantes</span> <span className="menu-arrow"></span></Link>
            </li>
            {cookies.CRCOOKIEMATERIA.plan===Constantes.planProfesional && <li className="submenu">
                <Link to="/menuprincipal"><i className="pi pi-sitemap" aria-hidden="true"></i> <span>Menú Principal</span> <span className="menu-arrow"></span></Link>
            </li>}
            <li className="submenu">
                <Link to="/faq"><i className="pi pi-question-circle" aria-hidden="true"></i> <span>FAQ</span> <span className="menu-arrow"></span></Link>
            </li>
            {cookies.CRCOOKIEMATERIA.plan===Constantes.planProfesional && <li className="submenu">
                <Link to="/tutorias"><i className="pi pi-comments" aria-hidden="true"></i> <span>Tutorias</span> <span className="menu-arrow"></span></Link>
            </li>}
            {cookies.CRCOOKIEMATERIA.plan===Constantes.planProfesional && <li className="submenu">
                <Link to="/notificaciones"><i className="pi pi-flag" aria-hidden="true"></i> <span>Notificaciones</span> <span className="menu-arrow"></span></Link>
            </li>}
            <hr/>
            <li className="menu-title"> 
                <span><i className="pi pi-file-excel" aria-hidden="true"></i> Reportes</span>
            </li>
            <li className="submenu">
                <Link to="/reportes/visualizaciones"> <span>Visualizaciones</span> <span className="menu-arrow"></span></Link>
                <Link to="/reportes/estudiantes"> <span>Estudiantes</span> <span className="menu-arrow"></span></Link>
            </li>
        </ul>
    }

    const MenuTutor = () => {
        return <ul>
            <li className="submenu">
                <Link to="/tutorias"><i className="pi pi-comments" aria-hidden="true"></i> <span>Tutorias</span> <span className="menu-arrow"></span></Link>
            </li>
        </ul>
    }
    
    return (
        <div className="sidebar" id="sidebar">
            <div className="sidebar-inner slimscroll">
                <div id="sidebar-menu" className="sidebar-menu">
                    {cookies.CRCOOKIEMATERIA?cookies.CRUSUARIO.tipo?<MenuTutor/>:<MenuAdmin/>
                    :''}
                </div>
            </div>
        </div>
    );
}

export default MenuLeft