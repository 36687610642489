import React, { useState, useRef, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import AdminServiciosAPI from '../servicios/AdminServicios';
import Tabla from '../core/Tabla';
import { Sidebar } from 'primereact/sidebar';
import EditarModulo from '../modulos/EditarModulo';
import { Toast } from 'primereact/toast';

const Modulos = () => { 
    const [cookies] = useCookies(['user']);
    const [idmodulo, setIdModulo] = useState(null);
    const [editarModulo, setEditarModulo] = useState(false);
    const refTabla = useRef(null);
    const mensaje = useRef(null);

    const columnas = [
        {field: 'orden', header: 'Orden', sortable:false, filter:false},
        {field: 'titulo', header: 'Titulo', sortable:false, filter:false},
        {field: 'estado', header: 'Estado', sortable:false, filter:false},
    ];

    const where = {where: {id_materia: cookies.CRCOOKIEMATERIA.id}};

    function onEditar(idmodulo){
        setIdModulo(idmodulo);
        setEditarModulo(true);
    }

    async function onDelete(seleccionados){
        let response = await AdminServiciosAPI.eliminarRegistro(cookies.CRCOOKIEUSUARIO,'modulos',{seleccionados: seleccionados, id_materia:cookies.CRCOOKIEMATERIA.id});
        if(response.error){
            mensaje.current.show({
                severity: 'warn',
                sticky: true,
                detail: `Lo sentimos tenemos problemas con red, intente en unos minutos.`
            });
        }else{
            const errormsj = await response.json();
            mensaje.current.show({
                severity: response.ok?'info':'warn',
                sticky: true,
                detail: errormsj
            });
            response.ok && refTabla.current.loadLazyData();
        }
    }

    const onCerrar = () => {
        setIdModulo(null);
        setEditarModulo(false);
    }

    const headerTabla = (
        <div className="table-header">
            <h5 className="p-m-0">Administración de Módulos</h5>
        </div>
    );

    return (
        <div>
            <Toast ref={mensaje} />
            <Tabla ref={refTabla} header={headerTabla} cookies={cookies} nombreTabla='modulos' columnas={columnas} where={where} onEditar={onEditar} onDelete={onDelete} />
            <Sidebar visible={editarModulo} fullScreen onHide={() => onCerrar()} icons={() => (
                <React.Fragment>
                    <h5 className="text-center">Editar Módulo</h5>
                </React.Fragment>
            )}>
                <EditarModulo moduloSeleccionado={idmodulo} refTabla={refTabla}/>
            </Sidebar>
        </div>
    );
}

export default Modulos