import React, { useState, useRef } from 'react';
import { useCookies } from 'react-cookie';
import Tabla from '../core/Tabla';
import { Button } from 'primereact/button';
import { BlockUI } from 'primereact/blockui';
import { Sidebar } from 'primereact/sidebar';
import { Config } from '../comun';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { formatDate } from '../servicios/Omni';

const Visualizaciones = () => {
    const [cookies] = useCookies(['user']);
    const refTabla = useRef(null);
    const [cargando, setCargando] = useState(false);
    const [filtrar, setFiltrar] = useState(false)
    const [fechas, setFechas] = useState()

    addLocale('es', {
        firstDayOfWeek: 1,
        showMonthAfterYear: true,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    const columnas = [
        {field: 'nombre', header: 'Archivo', sortable:true, filter:true},
        {field: 'visualizaciones', header: 'Visualizaciones', sortable:true, filter:true},
    ];

    const where = {where: {id_materia: cookies.CRCOOKIEMATERIA.id}};

    const onDescargar = async () =>{
        /*setCargando(true);
        const response = await PhpServiciosAPI.postPriv(cookies.CRCOOKIEUSUARIO,'visualizaciones',{id_materia:cookies.CRCOOKIEMATERIA.id});
        if(response.ok){
            const reporte = await response.json();
            saveAs(
                reporte.data.path,
                "reporte-visualizaciones.csv"
              );
            console.log('reporte generado',reporte);
        }else{
            console.log('reporte no generado');
        }
        setCargando(false);*/
        //console.log('fechas',formatDate(fechas[0]),formatDate(fechas[1]))
        let fecha2=''
        if(fechas[1]){
            fecha2= formatDate(fechas[1])
        }
        window.location.href=`${Config.apiPhp}/getReporteVisualizaciones/${cookies.CRCOOKIEMATERIA.id}/${formatDate(fechas[0])}/${fecha2}`
        
    }

    const opcionesTabla = (
            <React.Fragment>
                <Button label="Descargar detalle" icon="pi pi-download" className="p-button-secondary me-2" onClick={() => setFiltrar(true)} />
            </React.Fragment>
    );

    const headerTabla = (
        <div className="table-header">
            <h5 className="p-m-0">Visualizaciones de Archivos</h5>
        </div>
    );

    return(
        <BlockUI blocked={cargando}>
            <Tabla ref={refTabla} header={headerTabla} cookies={cookies} nombreTabla='materias/reportes/visualizaciones' 
                columnas={columnas} noNuevo={true} where={where} rightToolbarTemplate={opcionesTabla}/>
            <Sidebar position="right" className="ui-sidebar-md" visible={filtrar} onHide={() => setFiltrar(false)} icons={() => (
                <React.Fragment>
                    <h5 className="text-center">Descargar detalle</h5>
                </React.Fragment>
            )}>
                <div className="col-md-12">
                    <span className="p-field">
                        <label className="p-d-block w-100">Seleccione un rango de fechas</label>
                        <Calendar value={fechas} onChange={(e) => setFechas(e.value)} selectionMode="range" readOnlyInput dateFormat="dd/mm/yy" locale="es" />
                        {fechas && <Button label="Descargar" icon="pi pi-download" className="p-button-secondary me-2 mt-4" onClick={() => onDescargar()} />}
                    </span>
                </div>
                
            </Sidebar>
        </BlockUI>
    )
}

export default Visualizaciones