import React, { useEffect, useState, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import PhpServiciosAPI from '../servicios/PhpServicios';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Column } from 'primereact/column';
import { BlockUI } from 'primereact/blockui';

const EstudianteAvance = () => {
    const [cookies] = useCookies(['user']);
    const [datos, setDatos] = useState(null);
    const [cargando, setCargando] = useState(false);
    const dt = useRef(null);

    const cargarDatos = async () => {
        setCargando(true);
        const response = await PhpServiciosAPI.postPriv(cookies.CRCOOKIEUSUARIO,'reporteEstudiantes',{id_materia:cookies.CRCOOKIEMATERIA.id,id_sitio:cookies.CRCOOKIEMATERIA.id_sitio});
        if(response.ok){
            const datosTabla = await response.json();
            //console.log('datos',datosTabla);
            setDatos(datosTabla.data);
        }
        setCargando(false);
    }

    useEffect( () => {
		cargarDatos();
	}, []);

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    }

    const exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(datos.tabla);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'estudiantes');
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    const header = (
        <div className="flex align-items-center export-buttons">
            <Button type="button" icon="pi pi-file" onClick={() => exportCSV(false)} className="mr-2" data-pr-tooltip="CSV" />
            <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success p-mr-2" data-pr-tooltip="XLS" label='Descargar en Excel' />
        </div>
    );

    return(
        <BlockUI blocked={cargando}>
            {datos?<DataTable ref={dt}  header={header} dataKey="id"  value={datos.tabla} emptyMessage="No hay registros" paginator rows={10} alwaysShowPaginator={false} responsiveLayout="scroll" scrollable>
                {
                    datos.cabecera.map((col, index) => <Column style={{ minWidth: '120px' }} key={index} field={col.field} header={col.header} />)
                }
            </DataTable>:<ProgressSpinner />}
        </BlockUI>
    )
}

export default EstudianteAvance