import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import AdminServiciosAPI from '../servicios/AdminServicios';
import { Dialog } from 'primereact/dialog';

class Tabla extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            totalRecords: 0,
            registros: null,
            seleccionados: null,
            deleteSelectedDialog: false,
            lazyParams: {
                first: 0,
                rows: 10,
                page: 1,
                ...this.props.where
            }
        };

        this.loadLazyData = this.loadLazyData.bind(this);
        this.onPage = this.onPage.bind(this);
        this.onSort = this.onSort.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
        this.hideDeleteSelectedDialog = this.hideDeleteSelectedDialog.bind(this);
        this.estadoBodyTemplate = this.estadoBodyTemplate.bind(this);
        this.estRegistroBodyTemplate = this.estRegistroBodyTemplate.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    async loadLazyData(){
        this.setState({ loading: true });
        //imitate delay of a backend call
        const response = await AdminServiciosAPI.getItems(this.props.cookies.CRCOOKIEUSUARIO,this.props.nombreTabla,this.state.lazyParams);
        if(response.error){
            console.log('error','Problemas de red');
        }else if(response.ok){
            const data = await response.json();
            this.setState({
                totalRecords: data.totalRecords,
                registros: data.registros,
                loading: false
            });
        }else{
            const errormsj = await response.json();
            console.log('error',errormsj);
        }
        
    }

    onPage(event) {
        let lazyParams = { ...this.state.lazyParams, ...event };
        this.setState({ lazyParams }, this.loadLazyData);
    }

    onSort(event) {
        let lazyParams = { ...this.state.lazyParams, ...event };
        this.setState({ lazyParams }, this.loadLazyData);
    }

    onFilter(event) {
        let lazyParams = { ...this.state.lazyParams, ...event };
        lazyParams['first'] = 0;
        this.setState({ lazyParams }, this.loadLazyData);
    }

    componentDidMount() {
        this.loadLazyData()
    };

    actionBodyTemplate (rowData){
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => this.props.onEditar(rowData)} />
            </React.Fragment>
        );
    }

    confirmDeleteSelected(){
        this.setState({deleteSelectedDialog:true});
    }

    hideDeleteSelectedDialog(){
        this.setState({deleteSelectedDialog:false});
    }

    estadoBodyTemplate(rowData) {
        return <span className={rowData.estado_color}>{rowData.estado_nombre}</span>;
    }

    estRegistroBodyTemplate(rowData) {
        if(rowData.est_registro==='ACT'){
            return <span className="badge bg-primary">ACTIVO</span>;
        }else{
            return <span className="badge bg-secondary">INACTIVO</span>;
        }
        
    }

    onDelete(){
        this.props.onDelete(this.state.seleccionados);
        this.hideDeleteSelectedDialog();
    }

    render(){
        const dynamicColumns = this.props.columnas.map((col,i) => {
            let columna;
            switch(col.field){
                case 'estado_nombre':
                    columna = <Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} filter={col.filter} body={this.estadoBodyTemplate} />;
                    break;
                case 'est_registro':
                    columna = <Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} filter={col.filter} body={this.estRegistroBodyTemplate} />;
                    break;
                default:
                    columna = <Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} filter={col.filter} />;
            }
            return columna;
        });

        const deleteSelectedDialogFooter = (
            <React.Fragment>
                <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteSelectedDialog} />
                <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={() => this.onDelete()} />
            </React.Fragment>
        );
    
        const leftToolbarTemplate = () => {
            return (
                <React.Fragment>
                    {!this.props.noNuevo && <Button label="Nuevo" icon="pi pi-plus" className="p-button-success me-2" onClick={() => {this.props.onEditar(0)}} />}
                    {this.props.onDelete && <Button label="Eliminar" icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} disabled={!this.state.seleccionados || !this.state.seleccionados.length} />}
                </React.Fragment>
            )
        }

        return (
            <div>
                <div className="content-section implementation">
                    <div className="card">
                        <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={this.props.rightToolbarTemplate}></Toolbar>
                        <DataTable value={this.state.registros} lazy selection={this.state.seleccionados} onSelectionChange={(e) => this.setState({seleccionados: e.value})}
                            paginator first={this.state.lazyParams.first} rows={10} totalRecords={this.state.totalRecords} onPage={this.onPage}
                            onSort={this.onSort} sortField={this.state.lazyParams.sortField} sortOrder={this.state.lazyParams.sortOrder}
                            onFilter={this.onFilter} filters={this.state.lazyParams.filters} loading={this.state.loading} header={this.props.header}>
                                {this.props.onDelete?<Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>:null}
                                {dynamicColumns}
                                {this.props.onEditar?<Column body={this.actionBodyTemplate}></Column>:null}
                        </DataTable>
                        <Dialog visible={this.state.deleteSelectedDialog} style={{ width: '450px' }} header="Confirmación" modal footer={deleteSelectedDialogFooter} onHide={this.hideDeleteSelectedDialog}>
                            <div className="confirmation-content">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                                {this.state.seleccionados && <span>¿Está seguro de eliminar los items seleccionados?</span>}
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        );
    }
}

export default Tabla;