import React, { useState, useEffect } from 'react';
import { OrderList } from 'primereact/orderlist';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { obtenerIdNuevo } from '../servicios/Omni';

const Lecciones = ({listadoLecciones, setLecciones}) => { 
    const [nuevaLeccion, setNuevaLeccion] = useState('');
    const [lista, setLista] = useState(listadoLecciones);
    const [editarLeccion, setEditarLeccion] = useState(false);
    const [leccionSeleccionada, setLeccionSeleccionada] = useState(null);
    const [deleteSelectedDialog, setDeleteSelectedDialog] = useState(false);
    //const forceUpdate = React.useReducer(bool => !bool)[1];

    useEffect( () => {
        setLista(listadoLecciones);
    }, [listadoLecciones]);

    const ingresarLeccion = () => {
        if(nuevaLeccion!==''){
            const nueva = {
                id: obtenerIdNuevo(lista),
                titulo: nuevaLeccion
            }
            lista.push(nueva);
            setLecciones(lista);
            setLista(lista);
        }
        setNuevaLeccion('');
        setEditarLeccion(false);
        //forceUpdate();
    }

    const editaLeccion = () => {
        leccionSeleccionada.titulo = nuevaLeccion;
        const objIndex = lista.findIndex((obj => obj.id === leccionSeleccionada.id));
        lista[objIndex] = leccionSeleccionada;
        setLecciones(lista);
        setLista(lista);
        setNuevaLeccion('');
        setEditarLeccion(false);
    }

    const eliminarLeccion = () => {
        const objIndex = lista.findIndex((obj => obj.id === leccionSeleccionada.id));
        lista.splice(objIndex,1);
        setNuevaLeccion('');
        setEditarLeccion(false);
        hideDeleteSelected();
    }

    const limpiarLeccion = () => {
        setNuevaLeccion('');
        setEditarLeccion(false);
    }

    const onCambiaOrden = (listaOrdenada) => {
        setLecciones(listaOrdenada);
        setLista(listaOrdenada);
    }

    const onSeleccionLeccion = (item) => {
        setEditarLeccion(true);
        setNuevaLeccion(item.titulo);
        setLeccionSeleccionada(item);
    }

    const itemTemplate = (item) => {
        return (
            <div className="product-item" onClick={() => onSeleccionLeccion(item)}>
                <div className="product-list-detail">
                    <h6>{item.titulo}</h6>
                </div>
            </div>
        );
    }

    const confirmDeleteSelected = () => {
        setDeleteSelectedDialog(true);
    }

    const hideDeleteSelected = () => {
        setDeleteSelectedDialog(false);
    }

    const deleteSelectedDialogFooter = () => (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteSelected} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={eliminarLeccion} />
        </React.Fragment>
    );

    return (
        <div className="orderlist-demo">
            <div className="card">
                <div className="p-col-12 p-md-3">
                    <label for="tituloModulo" class="form-label">{editarLeccion?'Editar':'Nueva'} Lección:</label>
                    {editarLeccion?<div className="p-inputgroup mb-3">
                        <InputText value={nuevaLeccion} onChange={(e) => setNuevaLeccion(e.target.value)}/>
                        <Button icon="pi pi-save" className="p-button-success" onClick={() => {editaLeccion()}} title="Editar Lección"/>
                        <Button icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} title="Eliminar Lección"/>
                        <Button icon="pi pi-times" className="p-button-secondary" onClick={() => {limpiarLeccion()}} title="Nueva Lección"/>
                    </div>:<div className="p-inputgroup mb-3">
                        <InputText value={nuevaLeccion} onChange={(e) => setNuevaLeccion(e.target.value)}/>
                        <Button icon="pi pi-plus" className="p-button-info" onClick={() => {ingresarLeccion()}} title="Añadir Lección"/>
                    </div>}
                    <OrderList value={lista} header="Listado de Lecciones" dragdrop listStyle={{height:'auto'}} dataKey="id"
                    itemTemplate={itemTemplate} onChange={(e) => onCambiaOrden(e.value)}></OrderList>
                    <Dialog visible={deleteSelectedDialog} style={{ width: '450px' }} header="Confirmación" modal footer={deleteSelectedDialogFooter} onHide={hideDeleteSelected}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                            {editarLeccion && <span>Debe dar en Guardar para ejecutar cualquier acción. ¿Está seguro de eliminar la lección "{leccionSeleccionada.titulo}"?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

export default Lecciones