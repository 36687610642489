import React, { Component } from 'react';
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
import { OrderList } from 'primereact/orderlist';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { obtenerIdNuevo } from '../servicios/Omni';

class EditarEvaluacion extends Component{
    constructor(props) {
        super(props);
        this.state = {
            editarPregunta: false,
            listaPreguntas: this.props.listaPreguntas?this.props.listaPreguntas:[],
            deleteSelectedDialog: false,
            preguntaSeleccionada: {id:'', texto:'', opciones:[''], respuesta:null, puntaje:0},
        }
        this.onEditarPregunta = this.onEditarPregunta.bind(this);
        this.hideDeleteSelectedDialog = this.hideDeleteSelectedDialog.bind(this);
        this.onEliminarPregunta = this.onEliminarPregunta.bind(this);
        this.limpiarPregunta = this.limpiarPregunta.bind(this);
        this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
        this.onCambiaOrden = this.onCambiaOrden.bind(this);
        this.itemTemplate = this.itemTemplate.bind(this);
        this.onSeleccionarPregunta = this.onSeleccionarPregunta.bind(this);
        this.onIngresarPregunta = this.onIngresarPregunta.bind(this);
        this.onIngresarOpcion = this.onIngresarOpcion.bind(this);
        this.onInputOpcion = this.onInputOpcion.bind(this);
    }

    onIngresarPregunta(){
        this.state.preguntaSeleccionada.id=obtenerIdNuevo(this.state.listaPreguntas)
        this.state.listaPreguntas.push(this.state.preguntaSeleccionada);
        this.props.modificarEvaluacion(this.state.listaPreguntas);
        this.setState({listaPreguntas: this.state.listaPreguntas}, this.limpiarPregunta);
    }

    onIngresarOpcion(){
        let opciones = this.state.preguntaSeleccionada.opciones;
        opciones.push('');
        this.setState({preguntaSeleccionada: {...this.state.preguntaSeleccionada, opciones}});
    }

    onEditarPregunta(){
        const objIndex = this.state.listaPreguntas.findIndex((obj => obj.id === this.state.preguntaSeleccionada.id));
        this.state.listaPreguntas[objIndex]=this.state.preguntaSeleccionada;
        this.setState({listaPreguntas: this.state.listaPreguntas});
        this.props.modificarEvaluacion(this.state.listaPreguntas);
        this.limpiarPregunta();
    }

    onEliminarPregunta(){
        const objIndex = this.state.listaPreguntas.findIndex((obj => obj.id === this.state.preguntaSeleccionada.id));
        this.state.listaPreguntas.splice(objIndex,1);
        this.setState({listaPreguntas: this.state.listaPreguntas});
        this.props.modificarEvaluacion(this.state.listaPreguntas);
        this.hideDeleteSelectedDialog();
        this.limpiarPregunta();
    }

    onCambiaOrden(listaOrdenada){
        this.setState({listaPreguntas: listaOrdenada});
        this.props.modificarEvaluacion(listaOrdenada);
    }

    onSeleccionarPregunta(item){
        this.setState({preguntaSeleccionada: item, editarPregunta:true});
    }

    limpiarPregunta(){
        this.setState({preguntaSeleccionada: {id:'', texto:'', opciones:[''], respuesta:null, puntaje: 0}});
        this.setState({editarPregunta: false});
    }

    confirmDeleteSelected(){
        this.setState({deleteSelectedDialog:true});
    }

    hideDeleteSelectedDialog(){
        this.setState({deleteSelectedDialog:false});
    }

    itemTemplate(item){
        return (
            <div className="row" onClick={() => this.onSeleccionarPregunta(item)}>
                <div className="col-md-8">
                    <h6>{item.texto}</h6>
                </div>
                <div className="col-md-2">
                    <strong>Puntaje: </strong> {item.puntaje}
                </div>
                <div className="col-md-2">
                    <strong>Respuesta: </strong> {item.respuesta+1}
                </div>
            </div>
        );
    }

    onInputOpcion(valor,i){
        let opciones = this.state.preguntaSeleccionada.opciones;
        opciones[i]=valor;
        this.setState({preguntaSeleccionada: {...this.state.preguntaSeleccionada, opciones}});
    }

    render(){
        const deleteSelectedDialogFooter = (<React.Fragment>
                <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteSelectedDialog} />
                <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={this.onEliminarPregunta} />
            </React.Fragment>
        );

        return(<div className="orderlist-demo">
            <div className="card">
                <div className="p-col-12 p-md-3">
                    <label htmlFor="textoPregunta" className="form-label">{this.state.editarPregunta?'Editar':'Nueva'} Pregunta:</label>
                    <InputTextarea rows={3} cols={30} value={this.state.preguntaSeleccionada.texto} onChange={(e) => this.setState({preguntaSeleccionada: {...this.state.preguntaSeleccionada, texto: e.target.value}})} id="textoPregunta" className="w-100" required/>
                    <label className="form-label w-100 mt-1" htmlFor="puntajePregunta">Puntaje:</label>
                    <InputNumber inputId="puntajePregunta" value={this.state.preguntaSeleccionada.puntaje} onValueChange={(e) => this.setState({preguntaSeleccionada: {...this.state.preguntaSeleccionada, puntaje: e.value}})} showButtons mode="decimal" minFractionDigits={2}/>
                    <label className="form-label d-block mt-2">Opciones (seleccionar la respuesta):</label>
                    {this.state.preguntaSeleccionada.opciones.map((op,i) => {
                        return <div className="p-inputgroup mb-3" key={`oppr_${i}`}>
                            <RadioButton className="m-1" inputId={`opcion_${i}`} name="opcionpregunta" value={i} onChange={(e) => this.setState({preguntaSeleccionada: {...this.state.preguntaSeleccionada, respuesta: e.value}})} checked={this.state.preguntaSeleccionada.respuesta === i} />
                            <InputText value={op} onChange={(e) => this.onInputOpcion(e.target.value,i)}/>
                            {i===0 && <Button icon="pi pi-plus" className="p-button-info" onClick={() => {this.onIngresarOpcion()}} title="Añadir Opción"/>}
                        </div>
                    })}
                    {this.state.editarPregunta?<div className="text-center"><Button icon="pi pi-save" label="Editar Pregunta" className="p-button-success me-2" onClick={() => {this.onEditarPregunta()}} title="Editar Pregunta"/>
                        <Button icon="pi pi-trash" className="p-button-danger me-2" label="Eliminar Pregunta" onClick={() => this.confirmDeleteSelected()} title="Eliminar Pregunta"/>
                        <Button icon="pi pi-times" className="p-button-secondary" onClick={() => this.limpiarPregunta()} title="Nueva Pregunta"/>
                    </div>:<div className="text-center"><Button icon="pi pi-save" className="p-button-info" onClick={() => {this.onIngresarPregunta()}} label="Añadir Pregunta" title="Añadir Pregunta"/></div>}
                    <OrderList className="mt-3" value={this.state.listaPreguntas} header="Listado de Preguntas" dragdrop listStyle={{height:'auto'}} dataKey="id"
                        itemTemplate={this.itemTemplate} onChange={(e) => this.onCambiaOrden(e.value)}></OrderList>
                    <Dialog visible={this.state.deleteSelectedDialog} style={{ width: '450px' }} header="Confirmación" modal footer={deleteSelectedDialogFooter} onHide={this.hideDeleteSelectedDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                            {this.state.editarPregunta && <span>Debe dar en Guardar para ejecutar cualquier acción. ¿Está seguro de eliminar la pregunta "{this.state.preguntaSeleccionada.texto}"?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>);
    }
}

export default EditarEvaluacion