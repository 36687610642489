import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useCookies } from 'react-cookie';
import AdminServiciosAPI from '../servicios/AdminServicios';
import { Constantes, Config } from '../comun';
import { InputText } from "primereact/inputtext";
import { InputNumber } from 'primereact/inputnumber';
import { SelectButton } from 'primereact/selectbutton';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { CascadeSelect } from 'primereact/cascadeselect';
import JoditEditor from "jodit-react";
import { BlockUI } from 'primereact/blockui';

const EditarTema = ({temaSeleccionado, refTabla}) => { 
    const [cookies] = useCookies(['user']);
    const [titulo, setTitulo] = useState('');
    const [orden,  setOrden] = useState(1);
    const [estado,  setEstado] = useState(Constantes.estadoActivo);
    const mensaje = useRef(null);
    const editor = useRef(null);
    const [cargando, setCargando] = useState(false);
    const [guardando, setGuardando] = useState(false);
    const [editar, setEditar] = useState(false);
    const [texto, setTexto] = useState('');
    const [leccionSeleccionada, setLeccionSeleccionada] = useState(null);
    const [listaModulos, setListaModulos] = useState(null);
    const [idTema, setIdTema] = useState(null);

    useEffect( () => {
        async function cargarModulos(){
            setCargando(true);
            const response = await AdminServiciosAPI.getModulosLecciones(cookies.CRCOOKIEUSUARIO,cookies.CRCOOKIEMATERIA.id);
            if(response.ok){
                const modulos = await response.json();
                const modulosListos = modulos.map((m,i) => {
                    let lecciones = m.lecciones.map((l,y) => {
                        l.modulo = m.orden;
                        return l;
                    });
                    m.lecciones = lecciones;
                    return m;
                });
                setListaModulos(modulosListos);
            }
            setCargando(false);
        }
        if(listaModulos){
            if(typeof temaSeleccionado.titulo !== 'undefined'){
                cargarTema();
                setEditar(true);
            }
        }else{
            cargarModulos();
            setIdTema(uuidv4());
        }
    }, [listaModulos]);

    async function cargarTema(){
        setCargando(true);
        const response = await AdminServiciosAPI.getTema(cookies.CRCOOKIEUSUARIO,temaSeleccionado.id);
        if(response.ok){
            let tema = await response.json();
            tema = tema[0];
            setIdTema(tema.id);
            setTitulo(tema.titulo);
            setEstado(tema.est_registro);
            setOrden(tema.orden);
            setTexto(Buffer.from(tema.texto.data).toString('utf8'));
            listaModulos.forEach(m => {
                if(m.orden===tema.id_modulo){
                    m.lecciones.forEach(l => {
                        if(l.id===tema.id_leccion){
                            setLeccionSeleccionada(l);
                            return;
                        }
                    });
                }
            });
		}else{
            const errortema = await response.json();
            console.log('error al cargar tema',errortema);
        }
        setCargando(false);
    }

    const leftContents = (
        <React.Fragment>
            <Button label="Guardar" loading={guardando} icon="pi pi-save" className="p-mr-2" onClick={() => {onGuardar()}}/>
        </React.Fragment>
    );

    const onGuardar = async () => {
        let response;
        setGuardando(true);
        setCargando(true);
        //console.log('leccion',leccionSeleccionada)
        //console.log('modulos',listaModulos)
        const datos = {
            id_materia: cookies.CRCOOKIEMATERIA.id,
            id_modulo: leccionSeleccionada.modulo,
            id_leccion: leccionSeleccionada.id,
            orden: orden,
            titulo: titulo,
            est_registro: estado,
            texto: texto,
            leccion: leccionSeleccionada.titulo,
            modulo: listaModulos[leccionSeleccionada.modulo-1].titulo
        }
        if(editar){
            response = await AdminServiciosAPI.editarRegistro(cookies.CRCOOKIEUSUARIO,'temas',{datos:datos, where:{id: idTema}});
        }else{
            datos['id'] = idTema;
            response = await AdminServiciosAPI.nuevoRegistro(cookies.CRCOOKIEUSUARIO,'temas',datos);
        }
        if(response.error){
            mensaje.current.show({
                severity: 'warn',
                sticky: true,
                detail: `Lo sentimos tenemos problemas con red, intente en unos minutos.`
            });
        }else{
            const errormsj = await response.json();
            mensaje.current.show({
                severity: response.ok?'info':'warn',
                sticky: true,
                detail: errormsj
            });
            if(!editar){
                setIdTema(datos.id);
                response.ok && refTabla.current.loadLazyData();
            }else if(temaSeleccionado.titulo!==titulo){
                response.ok && refTabla.current.loadLazyData();
            }
            setEditar(response.ok);
            window.scrollTo(0, 0);
        }
        setCargando(false);
        setGuardando(false);
    }

    const onSeleccionaLeccion = async (leccion) => {
        setCargando(true);
        const response = await AdminServiciosAPI.getOrdenTema(cookies.CRCOOKIEUSUARIO,cookies.CRCOOKIEMATERIA.id,leccion.id);
        if(response.ok){
            const ultimo = await response.json();
            if(!isNaN(ultimo)){
                setOrden(ultimo+1);
            }
        }
        setLeccionSeleccionada(leccion);
        setCargando(false);
    }

    return (
        <BlockUI blocked={cargando}>
            <Toolbar className="mb-3 toolbaredicion" left={leftContents} />
            <Messages ref={mensaje} />
            <div className="mb-3">
                <label className="form-label w-100">Lección</label>
                <CascadeSelect required value={leccionSeleccionada} options={listaModulos}  optionLabel={"titulo"} optionGroupLabel={"titulo"} optionGroupChildren={['lecciones']}
                    style={{minWidth: '14rem'}} onChange={event => onSeleccionaLeccion(event.value)}/>
            </div>
            <div className="mb-3">
                <label htmlFor="tituloTema" className="form-label">Título del Tema</label>
                <InputText id="tituloTema" className="p-d-block form-control" onChange={(e) => setTitulo(e.target.value)} value={titulo} maxLength="200" required/>
            </div>
            <div className="row mb-3">
                <div className="col-md-6">
                    <label htmlFor="ordenTema" className="form-label block">Orden</label>
                    <InputNumber inputId="ordenTema" value={orden} onValueChange={(e) => setOrden(e.value)} showButtons min={1} max={100}/>
                </div>
                <div className="col-md-6">
                    <label htmlFor="estadoTema" className="form-label">Estado</label>
                    <SelectButton value={estado} options={Constantes.estRegistro} onChange={(e) => setEstado(e.value)} />
                </div>
            </div>
            <div className="">
                <label className="form-label w-100">Texto</label>
                <p className='text-muted'>Para insertar imagenes o archivos cargados como recursos puede usar la sigiuente url: <strong>{Config.cloudFront}{cookies.CRCOOKIEMATERIA.id}/recursos/</strong></p>
                <p className="text-muted">Utilice [monitorurl tipo="" url="" nombre=""] para visualizar una url y registrar la asistencia.  Puede utilizar los siguientes parametros:<br/>
                <strong>tipo</strong> puede ser: video, web, pdf, iframe
                <br/><strong>url</strong>: url del archivo es obligatorio
                <br/><strong>nombre</strong>: nombre del archivo para el reporte.</p>
                <p>Opciones para tipo video:<br/><strong>width</strong>: ancho del video ejemplo 640, <strong>height</strong>: alto del video ejemplo 270</p>
                <p>Para el tipo pdf, debe confirmar que el pdf se visualiza correctamente, si sale error puede abrir el pdf e imprimirlo a pdf y volver a cargar el archivo.</p>
                <JoditEditor
                    ref={editor}
                    value={texto}
                    config={{
                        readonly: false,
                        language: 'es',
                        i18n: 'es',
                        link: {
                            processVideoLink: false,
                            processPastedLink: false
                        }
                    }}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={newContent => setTexto(newContent)} // preferred to use only this option to update the content for performance reasons
                />
            </div>
        </BlockUI>
    );
}

export default EditarTema