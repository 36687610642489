import React, { useState, useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import AdminServiciosAPI from '../servicios/AdminServicios';
import { InputTextarea } from 'primereact/inputtextarea';
import { BlockUI } from 'primereact/blockui';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { Calendar } from 'primereact/calendar';

const EditarNotificacion = (props) => {
    const [cookies] = useCookies(['user']);
    const [texto, setTexto] = useState('');
    const [fechaInicio, setFechaInicio] = useState(null);
    const [fechaFin, setFechaFin] = useState(null);
    const mensaje = useRef(null);
    const [cargando, setCargando] = useState(false);
    const [guardando, setGuardando] = useState(false);

    useEffect( () => {
        async function cargarNotificacion(){
            setCargando(true);
            const response = await AdminServiciosAPI.listadoRegistros(cookies.CRCOOKIEUSUARIO,`notificaciones/${props.notificacionSeleccionado.id}`);
            if(response.ok){
                const datost = await response.json();
                setTexto(datost[0].texto);
                setFechaInicio(new Date(datost[0].fecha_inicio));
                setFechaFin(new Date(datost[0].fecha_fin));
            }
            setCargando(false);
        }
        console.log('item',props.notificacionSeleccionado)
        if(props.notificacionSeleccionado.id){
            cargarNotificacion();
        }
    }, []);

    const onGuardar = async() => {
        setGuardando(true);
        setCargando(true);
        let response;
        console.log('not',props.notificacionSeleccionado)
        if(props.notificacionSeleccionado.id>0){
            response = await AdminServiciosAPI.editarRegistro(cookies.CRCOOKIEUSUARIO,'notificaciones',{datos:{texto: texto, fecha_inicio: fechaInicio.toISOString().slice(0, 10), fecha_fin: fechaFin.toISOString().slice(0, 10)}, where:{id: props.notificacionSeleccionado.id}});
        }else{
            response = await AdminServiciosAPI.nuevoRegistro(cookies.CRCOOKIEUSUARIO,'notificaciones',{texto: texto, fecha_inicio: fechaInicio.toISOString().slice(0, 10), fecha_fin: fechaFin.toISOString().slice(0, 10), id_materia: cookies.CRCOOKIEMATERIA.id});
        }
        
        if(response.error){
            mensaje.current.show({
                severity: 'warn',
                sticky: true,
                detail: `Lo sentimos tenemos problemas con red, intente en unos minutos.`
            });
        }else{
            const errormsj = await response.json();
            if(errormsj>0){
                mensaje.current.show({
                    severity: 'info',
                    sticky: true,
                    detail: 'Notificación guardada con éxito'
                });
                //props.notificacionSeleccionado = {id: errormsj}
            }else{
                mensaje.current.show({
                    severity: response.ok?'info':'warn',
                    sticky: true,
                    detail: errormsj
                });
            }
            
            response.ok && props.refTabla.current.loadLazyData();
        }
        setCargando(false);
        setGuardando(false);
    }

    const leftContents = (
        <React.Fragment>
            <Button label="Guardar" loading={guardando} icon="pi pi-save" className="p-mr-2" onClick={() => {onGuardar()}}/>
        </React.Fragment>
    );

    return (
        <BlockUI blocked={cargando}>
            <Toolbar className="mb-3 toolbaredicion" left={leftContents} />
            <Messages ref={mensaje} />
            <div>
                <div className="row mb-3">
                    <label htmlFor='texto' className="form-label">Texto</label>
                    <InputTextarea id="texto" className="p-d-block form-control" style={{height: "100px"}} value={texto} onChange={(e) => setTexto(e.target.value)} rows={5} cols={30} />
                </div>
                <div className="row mb-3">
                    <div className='col-md-3'>
                        <label htmlFor="fechainicio" className="form-label">Fecha Inicio</label>
                        <Calendar id="fechainicio" value={fechaInicio} onChange={(e) => setFechaInicio(e.value)} showIcon />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="fechafin" className="form-label">Fecha Fin</label>
                        <Calendar id="fechafin" value={fechaFin} onChange={(e) => setFechaFin(e.value)} showIcon />
                    </div>
                </div>
            </div>
        </BlockUI>
    )
}

export default EditarNotificacion