import React, { useState, useRef } from 'react';
import { useCookies } from 'react-cookie';
import AdminServiciosAPI from '../servicios/AdminServicios';
import Tabla from '../core/Tabla';
import { Sidebar } from 'primereact/sidebar';
import EditarTema from '../modulos/EditarTema';
import { Toast } from 'primereact/toast';

const Temas = () => { 
    const [cookies] = useCookies(['user']);
    const [idTema, setIdTema] = useState(null);
    const [editarTema, setEditarTema] = useState(false);
    const refTabla = useRef(null);
    const mensaje = useRef(null);

    const columnas = [
        {field: 'modulo', header: 'Módulo', sortable:true, filter:true},
        {field: 'leccion', header: 'Lección', sortable:true, filter:true},
        {field: 'orden', header: 'Orden', sortable:true, filter:true},
        {field: 'titulo', header: 'Titulo', sortable:true, filter:true},
        {field: 'est_registro', header: 'Estado', sortable:true, filter:true},
    ];

    const where = {where: {id_materia: cookies.CRCOOKIEMATERIA.id}};

    function onEditar(idtema){
        setIdTema(idtema);
        setEditarTema(true);
    }

    async function onDelete(seleccionados){
        let response = await AdminServiciosAPI.eliminarRegistro(cookies.CRCOOKIEUSUARIO,'temas',{seleccionados: seleccionados});
        if(response.error){
            mensaje.current.show({
                severity: 'warn',
                sticky: true,
                detail: `Lo sentimos tenemos problemas con red, intente en unos minutos.`
            });
        }else{
            const errormsj = await response.json();
            mensaje.current.show({
                severity: response.ok?'info':'warn',
                sticky: true,
                detail: errormsj
            });
            response.ok && refTabla.current.loadLazyData();
        }
    }

    const onCerrar = () => {
        setIdTema(null);
        setEditarTema(false);
    }

    const headerTabla = (
        <div className="table-header">
            <h5 className="p-m-0">Administración de Temas</h5>
        </div>
    );

    return (
        <div>
            <Toast ref={mensaje} />
            <Tabla ref={refTabla} header={headerTabla} cookies={cookies} nombreTabla='temas' columnas={columnas} where={where} onEditar={onEditar} onDelete={onDelete} />
            <Sidebar visible={editarTema} fullScreen onHide={() => onCerrar()} icons={() => (
                <React.Fragment>
                    <h5 className="text-center">Editar Tema</h5>
                </React.Fragment>
            )}>
                <EditarTema temaSeleccionado={idTema} refTabla={refTabla}/>
            </Sidebar>
        </div>
    );
}

export default Temas