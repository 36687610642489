import React from 'react';
import './layout.css';
import Header from './Header';
import MenuLeft from './Menu';
import { Switch, Route } from "react-router-dom";
import { Modulos, Estudiantes, Materia, Temas } from '../mantenimiento';
import MenuPrincipal from '../materia/MenuPrincipal';
import { useCookies } from 'react-cookie';
import Faq from '../materia/Faq';
import Tutoria from '../mantenimiento/Tutoria';
import Visualizaciones from '../reportes/Visualizaciones';
import EstudianteAvance from '../reportes/EstudianteAvance';
import Notificaciones from '../mantenimiento/Notificaciones';

const Layout = () => {  
    const [cookies] = useCookies(['user']); 

    const OpcionesAdmin = () => {
        return <Switch>
            <Route path="/reportes/visualizaciones">
                <Visualizaciones />
            </Route>
            <Route path="/reportes/estudiantes">
                <EstudianteAvance />
            </Route>
            <Route path="/modulos">
                <Modulos />
            </Route>
            <Route path="/temas">
                <Temas />
            </Route>
            <Route path="/estudiantes">
                <Estudiantes />
            </Route>
            <Route path="/menuprincipal">
                <MenuPrincipal cookies={cookies}/>
            </Route>
            <Route path="/faq">
                <Faq cookies={cookies}/>
            </Route>
            <Route path="/tutorias">
                <Tutoria />
            </Route>
            <Route path="/notificaciones">
                <Notificaciones />
            </Route>
            <Route path="/">
                <Materia />
            </Route>
        </Switch>
    }

    const OpcionesTutor = () => {
        return <Switch>
            <Route path="/">
                <Tutoria />
            </Route>
        </Switch>
    }

    return (
        <div className="main-wrapper">
            <MenuLeft/>
            <Header/>
            <div className="page-wrapper">
                <div className="content container-fluid">
                {cookies.CRCOOKIEMATERIA?cookies.CRUSUARIO.tipo?<OpcionesTutor/>:<OpcionesAdmin/>:''}
                </div>
            </div>
        </div>
    )
}

export default Layout 