import React, { Component } from 'react';
import AdminServiciosAPI from '../servicios/AdminServicios';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { OrderList } from 'primereact/orderlist';
import { BlockUI } from 'primereact/blockui';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { InputArchivo, Config } from '../comun';
import JoditEditor from "jodit-react";
import { obtenerIdNuevo } from '../servicios/Omni';

const itemInicial = {
    id: null,
    titulo: '',
    imagen1: null,
    imagen2: null,
    texto: '',
}

class MenuPrincipal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            cargando: false,
            editarItem: false,
            listaItems: [],
            deleteSelectedDialog: false,
            itemSeleccionado: JSON.parse(JSON.stringify(itemInicial)),
        }
        this.onEditarItem = this.onEditarItem.bind(this);
        this.hideDeleteSelectedDialog = this.hideDeleteSelectedDialog.bind(this);
        this.onEliminarItem = this.onEliminarItem.bind(this);
        this.limpiarItem = this.limpiarItem.bind(this);
        this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
        this.onCambiaOrden = this.onCambiaOrden.bind(this);
        this.itemTemplate = this.itemTemplate.bind(this);
        this.onSeleccionarItem = this.onSeleccionarItem.bind(this);
        this.onIngresarItem = this.onIngresarItem.bind(this);
        this.cargarMenuPrincipal = this.cargarMenuPrincipal.bind(this);
        this.guardarMenuPrincipal = this.guardarMenuPrincipal.bind(this);
    }

    async cargarMenuPrincipal(){
        this.setState({cargando: true});
        const response = await AdminServiciosAPI.listadoRegistros(this.props.cookies.CRCOOKIEUSUARIO,`meta/${this.props.cookies.CRCOOKIEMATERIA.id}/menuprincipal`);
        if(response.ok){
            const datosmenu = await response.json();
            if(datosmenu.datos){
                this.setState({listaItems: datosmenu.datos});
            }
        }
        this.setState({cargando: false});
    }

    async guardarMenuPrincipal(){
        this.setState({cargando: true});
        if(this.state.itemSeleccionado.imagen1 && this.state.itemSeleccionado.imagen1.name){
            this.state.itemSeleccionado.imagen1.name=this.state.itemSeleccionado.id+'-'+this.state.itemSeleccionado.imagen1.name;
        }
        if(this.state.itemSeleccionado.imagen2 && this.state.itemSeleccionado.imagen2.name){
            this.state.itemSeleccionado.imagen2.name=this.state.itemSeleccionado.id+'-'+this.state.itemSeleccionado.imagen2.name;
        }
        await AdminServiciosAPI.nuevoRegistro(this.props.cookies.CRCOOKIEUSUARIO,'meta',{id_materia:this.props.cookies.CRCOOKIEMATERIA.id, tipo:'menuprincipal',datos: this.state.listaItems, seleccionado: this.state.itemSeleccionado});
        const objIndex = this.state.listaItems.findIndex((obj => obj.id === this.state.itemSeleccionado.id));
        if(this.state.itemSeleccionado.imagen1 && this.state.itemSeleccionado.imagen1.name){
            this.state.listaItems[objIndex].imagen1=this.state.itemSeleccionado.imagen1.name;
        }
        if(this.state.itemSeleccionado.imagen2 && this.state.itemSeleccionado.imagen2.name){
            this.state.listaItems[objIndex].imagen2=this.state.itemSeleccionado.imagen2.name;
        }
        this.setState({cargando: false});
    }

    componentDidMount(){
		this.cargarMenuPrincipal();
	}

    async onIngresarItem(){
        this.state.itemSeleccionado.id=obtenerIdNuevo(this.state.listaItems);
        this.state.listaItems.push(this.state.itemSeleccionado);
        await this.guardarMenuPrincipal();
        this.setState({listaItems: this.state.listaItems}, this.limpiarItem);
    }

    async onEditarItem(){
        const objIndex = this.state.listaItems.findIndex((obj => obj.id === this.state.itemSeleccionado.id));
        this.state.listaItems[objIndex]=this.state.itemSeleccionado;
        await this.guardarMenuPrincipal();
        this.setState({listaItems: this.state.listaItems}, this.limpiarItem);
    }

    async onEliminarItem(){
        const objIndex = this.state.listaItems.findIndex((obj => obj.id === this.state.itemSeleccionado.id));
        this.state.listaItems.splice(objIndex,1);
        await this.guardarMenuPrincipal();
        this.setState({listaItems: this.state.listaItems});
        this.hideDeleteSelectedDialog();
        this.limpiarItem();
    }

    async onCambiaOrden(listaOrdenada){
        this.setState({listaItems: listaOrdenada});
        await this.guardarMenuPrincipal();        
    }

    onSeleccionarItem(item){
        this.setState({itemSeleccionado: item, editarItem:true});
    }

    limpiarItem(){
        this.setState({itemSeleccionado: JSON.parse(JSON.stringify(itemInicial))});
        this.setState({editarItem: false});
    }

    confirmDeleteSelected(){
        this.setState({deleteSelectedDialog:true});
    }

    hideDeleteSelectedDialog(){
        this.setState({deleteSelectedDialog:false});
    }

    itemTemplate(item){
        return (
            <div className="row" onClick={() => this.onSeleccionarItem(item)}>
                <div className="col-md-8">
                    <h6>{item.titulo}</h6>
                </div>
            </div>
        );
    }

    render(){
        const deleteSelectedDialogFooter = (<React.Fragment>
                <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteSelectedDialog} />
                <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={this.onEliminarItem} />
            </React.Fragment>
        );
        return (
            <BlockUI blocked={this.state.cargando}>
                <Toast ref={(el) => this.toast = el} />

                <div className="orderlist-demo">
                    <div className="card">
                        <div className="p-col-12 p-md-3">
                            <label htmlFor="nombreMenu" className="form-label">Nombre</label>
                            <InputText id="nombreMenu" className="p-d-block form-control" onChange={(e) => this.setState({itemSeleccionado: {...this.state.itemSeleccionado, titulo:e.target.value}})} value={this.state.itemSeleccionado.titulo} required/>

                            <label htmlFor="contenidoMenu w-100 mt-1" className="form-label">Contenido</label>
                            <JoditEditor
                                id="contenidoMenu"
                                value={this.state.itemSeleccionado.texto}
                                config={{
                                    readonly: false,
                                    language: 'es',
                                    i18n: 'es',
                                    uploader: {
                                        insertImageAsBase64URI: true
                                    },
                                    link: {
                                        processVideoLink: false,
                                        processPastedLink: false
                                    }
                                }}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={newContent => this.setState({itemSeleccionado: {...this.state.itemSeleccionado, texto:newContent}})} // preferred to use only this option to update the content for performance reasons
                            />
                            
                            <label htmlFor="imagen2 w-100 mt-1" className="form-label">Imagen Normal</label>
                            <InputArchivo id="imagen1" valor={this.state.itemSeleccionado.imagen1} dato={this.state.itemSeleccionado.imagen1 && typeof this.state.itemSeleccionado.imagen1 === 'string' && {url:`${Config.cloudFront}${this.props.cookies.CRCOOKIEMATERIA.id}/menu/${this.state.itemSeleccionado.imagen1}`,mime:'IMAGEN'}}  setValorDato={(imagen) => this.setState({itemSeleccionado: {...this.state.itemSeleccionado, imagen1:imagen}})} />
                            <label htmlFor="imagen2 w-100 mt-1" className="form-label">Imagen Seleccionado</label>
                            <InputArchivo id="imagen2" valor={this.state.itemSeleccionado.imagen2} dato={this.state.itemSeleccionado.imagen2 && typeof this.state.itemSeleccionado.imagen2 === 'string' && {url:`${Config.cloudFront}${this.props.cookies.CRCOOKIEMATERIA.id}/menu/${this.state.itemSeleccionado.imagen2}`,mime:'IMAGEN'}}  setValorDato={(imagen) => this.setState({itemSeleccionado: {...this.state.itemSeleccionado, imagen2:imagen}})} />

                            {this.state.editarItem?<div className="text-center mt-1"><Button icon="pi pi-save" label="Editar Item" className="p-button-success me-2" onClick={() => {this.onEditarItem()}} title="Editar Item"/>
                                <Button icon="pi pi-trash" className="p-button-danger me-2" label="Eliminar Item" onClick={() => this.confirmDeleteSelected()} title="Eliminar Item"/>
                                <Button icon="pi pi-times" className="p-button-secondary" onClick={() => this.limpiarItem()} title="Nuevo Item"/>
                            </div>:<div className="text-center mt-1"><Button icon="pi pi-save" className="p-button-info" onClick={() => {this.onIngresarItem()}} label="Añadir Item" title="Añadir Item"/></div>}
                            <OrderList className="mt-3" value={this.state.listaItems} header="Items del Menú Principal" dragdrop listStyle={{height:'auto'}} dataKey="id"
                                itemTemplate={this.itemTemplate} onChange={(e) => this.onCambiaOrden(e.value)}></OrderList>
                            <Dialog visible={this.state.deleteSelectedDialog} style={{ width: '450px' }} header="Confirmación" modal footer={deleteSelectedDialogFooter} onHide={this.hideDeleteSelectedDialog}>
                                <div className="confirmation-content">
                                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                                    {this.state.editarItem && <span>Debe dar en Guardar para ejecutar cualquier acción. ¿Está seguro de eliminar la item "{this.state.itemSeleccionado.texto}"?</span>}
                                </div>
                            </Dialog>
                        </div>
                    </div>
                </div>
            </BlockUI>
        );
    }
    
}

export default MenuPrincipal